import { format } from "date-fns";
import download from "js-file-download";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  DataTable,
  Icon,
  PaginationComponent,
  Sidebar,
  UserAvatar,
} from "../../../../components/Component";
import FilteredByList from "../../../../components/FilteredBy";
import Check from "../../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import { MaintenanceContext } from "../../../../providers/Maintenance.provider";
import ModalViewer from "../../ModalViewer";
import { RemedialContext } from "../RemedialProvider";
import { exportParticularTask } from "../RemedialRepository";
import RemedialMaintenanceFormContainer from "../containers/RemedialMaintenanceFormContainer";
import RemedialMaintenanceViewContainer from "../containers/RemedialMaintenanceViewContainer";
import ExportForm, { TASK_FIELDS } from "./ExportForm";
import RemedialListFilter from "./RemedialListFilter";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { statusColorTheme } from "../../../../constants/constants";
import { createColumnHelper } from "@tanstack/react-table";
import { findUpper, openImageInNewTab } from "../../../../utils/Utils";
import { updateViewSettings } from "../../../../repositories/ViewRepository";
import { createPortal } from "react-dom";
import TanstackTable from "../../../../components/tableV1/Table";
import SortableColumns from "../../../../components/tableV1/SortableColumns";
import { taskCategory } from "../../../../constants/constants";
import useBoolean from "../../../../hooks/useBoolean";
import ToolTip from "../../../../components/ToolTip";

const columnHelper = createColumnHelper();

const RemedialListCompact = () => {
  const location = useLocation().search;

  const taskId = JSON.parse(new URLSearchParams(location).get("id"));

  // REFS
  const focusSearchRef = React.useRef();

  const remedialContext = useContext(RemedialContext);
  const { filterOptions, userList } = useContext(MaintenanceContext);
  const { remedialList, pagination, unarchiveTask, archiveTask, tasksColumnSettings,
    setTasksColumnSettings } = remedialContext;
  const {
    filterParams,
    handleApplyFilter,
    deleteTask,
    remedialLoading,
    sfState,
    showFilter,
    isFilterApplied,
  } = remedialContext;

  const [sm, updateSm] = useState(false);
  const [onSearch, setOnSearch] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [task, setTask] = useState("");
  const [view, setView] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [isExpotModalOpen, setIsExportModalOpen] = useState(false);
  const [isColumnArrangeModalOpen, icamState] = useBoolean(false);
  const [selectedAttachment, setSelectedAttachment] = useState({});
  const [isAttachmentModalOpen, iamoState] = useBoolean(false);

  const renderTableValue = (tableValue) => {
    if (!tableValue) {
      return <div>-</div>;
    }
    return <div data-toggle="tooltip" style={{ cursor: "pointer" }} title={tableValue}>{tableValue} </div>;
  };

  function tableColumn(columnSetting) {
    switch (columnSetting.field_key) {
      case "title":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => "Ticket Name",
          cell: (info) => {
            return (
              <div style={{ display: "flex", gap: "10px", cursor: "pointer", }}
                onClick={() => {
                  viewTask(info.row.original.id);
                }}>
                <div className="user-card">
                  <UserAvatar theme={"primary"} className="xs" text={findUpper(info.row.original?.title)} />
                  <div className="user-name"></div>
                  <span
                    data-toggle="tooltip"
                    title={info.row.original?.title}
                    style={{
                      width: info.row.original?.title?.length > 50 ? "350px" : "",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                    className="tb-lead"
                  >
                    {info.row.original?.title || "-"}
                  </span>
                </div>
                {/* <p style={{ fontWeight: "bold", color: "#364a63" }}>{renderTableValue(info.getValue())}</p> */}
              </div>
            );
          },
        });
      case "status":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.status) {
              return (
                <>
                  <Badge className="text-capitalize" style={statusColorTheme[info.row.original?.status]} pill>
                    <span>{STATUSES[info.row.original?.status] || "-"}</span>
                  </Badge>
                </>
              )

            }
            else return "-"
          }
        });
      case "identifier":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "startedAt":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.startedAt) {
              return `${format(new Date(info.row.original?.startedAt || null), "dd/MM/yyyy")}`
            }
            return "-"
          }
        });
      case "resolvedAt":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.resolvedAt) {
              return `${format(new Date(info.row.original?.resolvedAt || null), "dd/MM/yyyy")}`
            } else {
              return "-"
            }
            return "-"
          }
        });
      case "plantId":
        return columnHelper.accessor("plants", {
          id: "plantId",
          enableResizing: true,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.row.original.plants?.name),
        });
      case "isArchived":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <p>{info.getValue() ? "Archived" : "UnArchived"}</p>,
        });
      case "priority":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => renderTableValue(info.getValue()),
        });
      case "description":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <ToolTip id={`id-${info.row.original?.id}-description`} value={info.getValue()} />
        });
      case "comment":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <ToolTip id={`id-${info.row.original?.id}-comment`} value={info.getValue()} />,
        });
      case "labourHours":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => <p >{info.getValue() ? info.getValue() : "-"}</p>,
        });
      case "category":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.category) {
              return `${taskCategory(info.row.original.category)}`
            }
          }
        });
      case "assetCategoryId":
        return columnHelper.accessor("assetCategory", {
          enableResizing: true,
          id: "assetCategoryId",
          header: () => "Asset Category",
          cell: (info) => renderTableValue(info.row.original?.assetCategory?.name),
        });
      case "teamLeaderId":
        return columnHelper.accessor("users", {
          id: "teamLeaderId",
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.users && Object.keys(info.row.original?.users).length) {
              return `${info.row.original?.users?.firstName} ${info.row.original?.users?.lastName}`;
            }
            return "-";
          },
        });
      case "createdById":
        return columnHelper.accessor("createdBy", {
          id: "createdById",
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (info.row.original?.createdBy) {
              return (
                <p>
                  {info.row.original?.createdBy?.firstName}
                  {info.row.original?.createdBy?.lastName}
                </p>
              );
            }
            return "-";
          },
        });
      case "fieldEngineerIds":
        return columnHelper.accessor("fieldEngineerIds", {
          id: columnSetting.field_key,
          enableSorting: false,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (!info.row.original?.fieldEngineerIds?.length) {
              return <p>-</p>;
            }
            const user = userList.filter((item) => info.row.original?.fieldEngineerIds.includes(item.value));
            return (
              <div style={{ display: "flex" }}>
                {user.map((u, i) => {
                  return (
                    <div key={`field-engineer-${i}`}>
                      {u.label}
                      {i !== user.length - 1 && <span>,&nbsp;</span>}
                    </div>
                  );
                })}
              </div>
            );
          },
        });
      case "assignedToIds":
        return columnHelper.accessor("assignedToIds", {
          id: columnSetting.field_key,
          enableSorting: false,
          enableResizing: false,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (!info.row.original?.assignedToIds?.length) {
              return <p>-</p>;
            }
            const user = userList.filter((item) => info.row.original?.assignedToIds.includes(item.value));
            return (
              <div style={{ display: "flex" }}>
                {user.map((u, i) => {
                  return (
                    <div key={`assigned-engineer-${i}`}>
                      {u.label}
                      {i !== user.length - 1 && <span>,&nbsp;</span>}
                    </div>
                  );
                })}
              </div>
            );
          },
        });
      case "attachment":
        return columnHelper.accessor(columnSetting.field_key, {
          id: columnSetting.field_key,
          enableSorting: false,
          header: () => columnSetting.field_name ?? "",
          cell: (info) => {
            if (!info.row.original?.attachment?.length) {
              return <p>No Attachments</p>;
            }
            return (
              <div style={{ display: "flex" }}>
                {info.row.original?.attachment?.length > 2 ? (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {info.row.original?.attachment?.slice(0, 2).map((attachment, i) => {
                      return (
                        <div
                          onClick={() => openImageInNewTab(attachment?.files.key)}
                          key={`attachment-${i}`}
                          style={{ color: "#0000EE", cursor: "pointer" }}
                        >
                          {attachment?.files?.name}
                          {i !== info.row.original?.attachment?.length - 1 && <span>,&nbsp;</span>}
                        </div>
                      );
                    })}
                    <span
                      onClick={() => {
                        setSelectedAttachment({
                          taskName: info.row.original?.title,
                          attachments: info.row.original?.attachment.slice(2),
                        });
                        iamoState.on();
                      }}
                      style={{ color: "#fff", cursor: "pointer" }}
                      class="badge rounded-pill badge-xs bg-primary"
                    >
                      +{info.row.original?.attachment?.length - 2}
                    </span>
                  </div>
                ) : (
                  info.row.original?.attachment?.map((attachment, i) => {
                    return (
                      <div
                        onClick={() => openImageInNewTab(attachment?.files.key)}
                        key={`attachment-${i}`}
                        style={{ color: "#0000EE", cursor: "pointer" }}
                      >
                        {attachment?.files?.name}
                        {i !== info.row.original?.attachment?.length - 1 && <span>,&nbsp;</span>}
                      </div>
                    );
                  })
                )}
              </div>
            );
          },
        });
      default:
        return columnHelper.accessor(columnSetting.field_key, {
          id: "id",
          enableSorting: false,
          header: () => columnSetting.field_key ?? "",
          cell: (info) => {
            return <p>unknown column</p>;
          },
        });
    }
  }

  const onSearchAction = () => {
    handleApplyFilter({ ...filterParams, searchText });
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  async function handleUpdateViewSettings(payload) {
    return await updateViewSettings(payload);
  }

  const handleNameSort = (sortOrder, field) => {
    handleApplyFilter({ ...filterParams, sortingOrder: sortOrder, sortingField: field });
    const updatedSettings = {
      ...tasksColumnSettings,
      filters: { ...tasksColumnSettings.filters, sortingField: field, sortingOrder: sortOrder },
    };
    handleUpdateViewSettings(updatedSettings);
    setTasksColumnSettings(updatedSettings);
  };
  let engineers = {};

  const STATUSES = {
    OPEN: "Open",
    IN_PROGRESS: "In Progress",
    READY_FOR_APPROVAL: "Ready for Approval",
    COMPLETED: "Completed",
  };
  const VIEW = {
    VIEW: "VIEW",
    CREATE: "CREATE",
    EDIT: "EDIT",
  };

  useEffect(() => {
    if (focusSearchRef.current) focusSearchRef.current.focus();
  });

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to toggle the search option
  const toggleSearch = () => setOnSearch(!onSearch);

  const handleArchive = async (taskId) => {
    archiveTask(taskId)
      .then((res) => {
        successToast({ description: " Remedial Task Archived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while archive Remedial Task" });
      });
  };
  const handleUnArchive = async (taskId) => {
    unarchiveTask(taskId)
      .then((res) => {
        successToast({ description: "Remedial Task UnArchived successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while unarchive Remedial Task" });
      });
  };

  const handleDeleteRemedial = async (taskId) => {
    deleteTask(taskId)
      .then((res) => {
        successToast({ description: "Remedial task is deleted successfully" });
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting Remedial task" });
      });
  };

  const handleDeleteConfirmation = async (taskId) => {
    confirmationSweetAlert({
      id: taskId,
      handleConfirmation: handleDeleteRemedial,
    });
  };

  const exportParticularTaskFuntion = async (taskId) => {
    try {
      setExportLoading(true);
      const response = await exportParticularTask({ taskId, category: "remedial" });
      download(response, `task.csv`);
      successToast({ description: "Successfully Exported" });
      setExportLoading(false);
    } catch (err) {
      errorToast({ description: "Error happened while exporting tasks" });
      setExportLoading(false);
    }
  };

  for (let i = 0; i < filterOptions.assignedEngineer?.length; i++) {
    engineers[filterOptions.assignedEngineer[i].value] = filterOptions.assignedEngineer[i].label;
  }

  const viewTask = (taskId) => {
    setTask(taskId);
    setView(VIEW.VIEW);
    toggleModal();
  };
  useMemo(() => {
    if (taskId) {
      viewTask(taskId);
    }
  }, [taskId]);

  const editTask = (taskId) => {
    setTask(taskId);
    setView(VIEW.EDIT);
    setIsOpen(true);
  };

  const createTask = () => {
    setView(VIEW.CREATE);
    setIsOpen(!isOpen);
    return <>Edit</>;
  };

  const onSuccessfulModal = () => {
    toggleModal();
  };

  const createExport = () => {
    setView(VIEW.CREATE);
    setIsExportModalOpen(!isExpotModalOpen);
    return <>Edit</>;
  };

  const toggleExportModal = () => {
    setIsExportModalOpen(!isExpotModalOpen);
  };

  const getExportComponent = () => {
    if (view === VIEW.CREATE) {
      return <ExportForm
        onSuccessfulModal={onSuccessfulModal}
        filterParams={filterParams}
        setIsExportModalOpen={setIsExportModalOpen} />;
    }
  };

  const getComponent = () => {
    if (view === VIEW.VIEW) {
      return <RemedialMaintenanceViewContainer taskId={task} setIsOpen={setIsOpen} editTask={editTask} />;
    } else if (view === VIEW.CREATE) {
      return <RemedialMaintenanceFormContainer onSuccessfulModal={onSuccessfulModal} />;
    } else if (view === VIEW.EDIT) {
      return <RemedialMaintenanceFormContainer taskId={task} onSuccessfulModal={onSuccessfulModal} />;
    }
  };

  const assignedEngineerData = React.useMemo(() => {
    if (filterParams && filterParams.selectedAssignedEngineer.length) {
      const engineersData = filterParams.selectedAssignedEngineer.map((item) => {
        return filterOptions.assignedEngineer?.[
          filterOptions.assignedEngineer?.findIndex((engineerData) => engineerData.value === item)
        ];
      });
      return engineersData
    }
  }, [filterParams, filterOptions])

  const plantSelectedData = React.useMemo(() => {
    if (filterParams) {
      const plantsData = filterParams.selectedPlant && filterParams.selectedPlant.map((item) => {
        return filterOptions.plants?.[filterOptions.plants?.findIndex((plantData) => plantData.value === item)];
      })
      return plantsData
    }
  }, [filterParams, filterOptions]
  )

  const columns = React.useMemo(() => {
    if (tasksColumnSettings?.fields?.length) {
      return tasksColumnSettings.fields
        .filter((cs) => cs.isChecked)
        .map((columnSetting) => {
          return tableColumn(columnSetting);
        });
    }
    return [];
  }, [tasksColumnSettings]);

  const handleClearSearch = async () => {
    if (!searchText && !filterParams.searchText) {
      toggleSearch()
    }
    else {
      setSearchText("");
      handleApplyFilter({ ...filterParams, searchText: "" });
      toggleSearch();
    }
  }

  const renderAction = React.useMemo(() => {
    return [
      columnHelper.accessor("id", {
        header: "",
        size: 40,
        enableSorting: false,
        enableResizing: false,
        cell: (info) => {
          const item = info?.row.original;
          return (
            <ul className="nk-tb-actions gx-1">
              <UncontrolledDropdown>
                <DropdownToggle id="menu-btn-remedial" tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                  <Icon name="more-h"></Icon>
                </DropdownToggle>
                {createPortal(
                  <DropdownMenu right>
                    <ul className="link-list-opt no-bdr">
                      <React.Fragment>
                        <Check permission={["manageTickets"]}>
                          {(item?.plants && item.plants.status === "ACTIVE")
                            &&
                            <>
                              <li /* className="nk-tb-action-hidden" */ onClick={() => editTask(item.id)}>
                                <DropdownItem
                                  id="edit-remedial-btn"
                                  tag="a"
                                  href="#edit"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                >
                                  <Icon name="edit-alt-fill"></Icon>
                                  <span>Edit Task</span>
                                </DropdownItem>
                              </li>
                              <li className="divider"></li>

                            </>
                          }
                          {item.isArchived ? (
                            <React.Fragment>
                              <li onClick={() => handleUnArchive(item.id)}>
                                <DropdownItem
                                  id="unarchive-remedial"
                                  tag="a"
                                  href="#unarchive"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                >
                                  <Icon name="unarchive"></Icon>
                                  <span>UnArchive Task</span>
                                </DropdownItem>
                              </li>
                              <li className="divider"></li>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <li onClick={() => handleArchive(item.id)}>
                                <DropdownItem
                                  id="archive-remedial"
                                  tag="a"
                                  href="#archive"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                >
                                  <Icon name="archive"></Icon>
                                  <span>Archive Task</span>
                                </DropdownItem>
                              </li>
                            </React.Fragment>
                          )}
                        </Check>
                        <Check permission={["manageTickets"]}>
                          {item.status !== "SUSPENDED" && (
                            <>
                              <li className="divider"></li>
                              <li onClick={() => handleDeleteConfirmation(item.id)}>
                                <DropdownItem
                                  id="delete-remedial"
                                  tag="a"
                                  href="#delete"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                >
                                  <Icon name="trash"></Icon>
                                  <span>Delete Task</span>
                                </DropdownItem>
                              </li>
                            </>
                          )}
                        </Check>
                        <Check permission={["getTickets", "manageTickets"]}>
                          <li className="divider"></li>
                          <li isLoading={exportLoading} onClick={() => exportParticularTaskFuntion(item.id)}>
                            <DropdownItem
                              id="export-remedial"
                              tag="a"
                              href="#export"
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            >
                              <Icon name="download"></Icon>
                              <span>Export</span>
                            </DropdownItem>
                          </li>
                        </Check>
                      </React.Fragment>
                    </ul>
                  </DropdownMenu>,
                  document.body
                )}
              </UncontrolledDropdown>

            </ul>
          );
        },
      }),
    ];
  }, [remedialList.items]);

  const renderTable = React.useMemo(() => {
    return (
      <TanstackTable
        dataSource={remedialList.items ?? []}
        _column={[...columns, ...renderAction]}
        columnSettings={tasksColumnSettings}
        onColumWidthChanged={(resizedColumn) => {
          const _tasksColumnSettings = [...tasksColumnSettings.fields].map((ts) => {
            if (ts.field_key === resizedColumn.field_key) {
              return {
                ...ts,
                width: resizedColumn.width,
              };
            } else {
              return ts;
            }
          });
          handleUpdateViewSettings({
            // ...pvPlantColumnSettings,
            entityType: "REMEDIAL_MAINTENANCE",
            fields: _tasksColumnSettings,
          });
          setTasksColumnSettings((prev) => ({ ...prev, fields: _tasksColumnSettings }));
        }}
        handleSorting={(sortingParams) => {
          handleNameSort(sortingParams.sortingOrder, sortingParams.sortingField, sortingParams.table);
        }}
      />
    );
  }, [columns, remedialList.items, tasksColumnSettings]);

  return (
    <React.Fragment>
      {
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Remedial Maintenance Tickets
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have a total of {pagination?.totalCount || 0} remedial maintenance tickets.</p>
              </BlockDes>
              <div className="d-flex flex-wrap">
                {filterParams.selectedTaskStatus && (
                  <FilteredByList
                    data={filterParams.selectedTaskStatus}
                    title={"Status"}
                    isNotMap={true}
                    isStr={false}
                  />
                )}
                {plantSelectedData ? (
                  <FilteredByList data={plantSelectedData} title={"Plant"} isMap={true} isStr={false} />
                ) : null}
                {assignedEngineerData && (
                  <FilteredByList
                    data={assignedEngineerData}
                    title={"Assigned Engineer"}
                    isMap={true}
                    isStr={false}
                  />
                )}
                {filterParams.searchText && (
                  <FilteredByList data={filterParams.searchText} title="Searched by" isNotMap={true} isStr={true} />
                )}
                <FilteredByList data={filterParams.startDate} title={"Start Date"} isMap={false} isStr={true} />
                <FilteredByList data={filterParams.resolvedDate} title={"Resolved Date"} isMap={false} isStr={true} />
              </div>
            </BlockHeadContent>

            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <Check permission={["getTickets", "manageTickets"]}>
                      <li>
                        <Button
                          id="export-remedial"
                          disabled={pagination?.totalCount === 0}
                          className={`btn btn-white btn-outline-light`}
                          type="export"
                          isLoading={exportLoading}
                          onClick={createExport}
                        >
                          <Icon name="download-cloud"> </Icon>
                          <span>Export</span>
                        </Button>
                      </li>
                    </Check>
                    <Check permission={["manageTickets"]}>
                      <li className="nk-block-tools-opt">
                        <Button id="add-remedial" color="primary" className="btn-icon" onClick={createTask}>
                          <Icon name="plus"></Icon>
                        </Button>
                      </li>
                    </Check>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
      }
      <Block>
        <DataTable className="card-stretch">
          <div className="card-inner position-relative card-tools-toggle ">
            <div className="card-title-group ">
              <div className="card-tools"></div>
              <div className="card-tools mr-n1 ">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      id="search-remedial"
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleSearch();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <div>
                    <Sidebar toggleState={showFilter}>
                      <div>
                        <RemedialListFilter filterOptions={filterOptions} isPlantName={true} />
                      </div>
                    </Sidebar>
                    <div id="filter-remedial" tag="a" className="btn btn-icon " onClick={() => sfState.toggle()}>
                      {isFilterApplied ? <div className="dot dot-primary"></div> : null}
                      <Icon name="filter-alt"></Icon>
                    </div>
                    {(remedialList?.pagination?.totalCount > 0) &&
                      <div id="task-filter" tag="a" className="btn btn-icon " onClick={() => icamState.on()}>
                        <Icon name="view-list-fill"></Icon>
                      </div>
                    }
                    <ModalViewer
                      title={`${selectedAttachment.taskName}'s Attachments`}
                      isOpen={isAttachmentModalOpen}
                      size="md"
                      toggleModal={iamoState.toggle}
                      component={
                        Object.keys(selectedAttachment).length ? (
                          <div>
                            {selectedAttachment?.attachments.map((attachment) => {
                              return (
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <em class="icon ni ni-dot"></em>
                                  <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() => openImageInNewTab(attachment?.files.key)}
                                  >
                                    <div style={{ color: "#0000EE", cursor: "pointer" }} href="">{attachment?.files?.name}</div>
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <></>
                        )
                      }
                    />
                    <ModalViewer
                      title="Remedial Task Columns"
                      isOpen={isColumnArrangeModalOpen}
                      size="md"
                      toggleModal={icamState.toggle}
                      component={
                        <SortableColumns
                          entityType={"REMEDIAL_MAINTENANCE"}
                          fields={TASK_FIELDS}
                          updateViewSettings={async (modifiedSettings) => {
                            const updatedSettings = await handleUpdateViewSettings({
                              ...tasksColumnSettings,
                              fields: modifiedSettings,
                            });
                            setTasksColumnSettings((prev) => ({ ...prev, fields: updatedSettings.fields }));
                          }}
                          closeModal={icamState.off}
                          visibleColumns={tasksColumnSettings.fields}
                        />
                      }
                    />
                  </div>
                </ul>
              </div>
            </div>
            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
              <div className="card-body">
                <div className="search-content">
                  <Button
                    id="cancel-search-remedial"
                    className="search-back btn-icon toggle-search active"
                    onClick={handleClearSearch}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                  <input
                    id="serach-input-remedial"
                    type="text"
                    className="border-transparent form-focus-none form-control"
                    placeholder="Search by Ticket Name, Status, Plant Name.enter to search"
                    ref={(el) => {
                      focusSearchRef.current = !onSearch ? el : null;
                    }}
                    value={searchText}
                    onChange={(e) => onFilterChange(e)}
                    onKeyDown={(e) => {
                      //13 -> Enter
                      if (e.keyCode === 13) {
                        onSearchAction();
                      }
                      //27 -> Escape
                      if (e.keyCode === 27) {
                        handleClearSearch()
                      }
                    }}
                  />
                  <Button id="tab-to-search-remedial" className="search-submit btn-icon">
                    <Icon name="search" onClick={onSearchAction}></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {remedialLoading || remedialContext.isLoadingFilters ? <Skeleton count={10} className="w-100" /> :
            (remedialList?.pagination?.totalCount > 0) && renderTable}

          <div className="card-inner">
            {remedialList.pagination && remedialList.pagination.totalCount > 0 ? (
              <PaginationComponent
                itemPerPage={filterParams.size}
                totalItems={remedialList.pagination.totalCount}
                paginate={(page) => {
                  remedialContext.handleApplyFilter({ ...filterParams, page });
                }}
                currentPage={remedialList.pagination.currentPage}
                paginatePage={(size) => {
                  remedialContext.handleApplyFilter({ ...filterParams, page: 1, size });
                }}

              />
            ) :
              (!remedialLoading && remedialList?.pagination?.totalCount === 0) && (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )
            }
          </div>
        </DataTable>
      </Block>
      <ModalViewer
        title="Remedial Maintenance Ticket"
        isOpen={isOpen}
        toggleModal={toggleModal}
        component={getComponent()}
      />
      <ModalViewer
        title="Remedial Maintenance"
        isOpen={isExpotModalOpen}
        size="md"
        toggleModal={toggleExportModal}
        component={getExportComponent()}
      />
    </React.Fragment>
  );
};
export default RemedialListCompact;

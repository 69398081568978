import React, { useContext, useEffect } from "react";
import Content from "../../../../../layout/content/Content";
import Head from "../../../../../layout/head/Head";
import { PreventativeTaskContext } from "../PreventativeTaskProvider";
import PreventativeGenerator from "../components/PreventativeGeneratorForm";

const PreventiveMaintenanceContainer = props => {
    const { loadOptions } = useContext(PreventativeTaskContext)

    useEffect(() => {
        loadOptions()
    }, [])

    return (
        <React.Fragment>
            <Head title="Preventative Task" />
            <Content>
                <PreventativeGenerator />
            </Content>
        </React.Fragment>
    );
}

export default PreventiveMaintenanceContainer
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import ViewPvPlant from "../components/PvPlantView";
import { PvPlantManagementContext, withPvPlantManagementProvider } from "../PvPlantManagementProvider";
import { ActivityLogContext } from "../../../providers/ActivtiyLogProvider";
import { StringContext } from "../../maintenance/string/StringProvider";
import { MaintenanceContext } from "../../../providers/Maintenance.provider";
import { MAINTENANCE_CATEGORY } from "../../../constants/constants";

const PvPlantManagementViewContainer = (props) => {
  const location = useLocation().search;
  const status = new URLSearchParams(location).get("status");
  const tab = new URLSearchParams(location).get("tab");

  const params = useParams();
  const { plantId } = params;
  const { loadPvPlant, loadAllUsers, loadAllDnos, loadAllHealthAndSafeties, loadPvPlantTask, loadAttributeMasterList } =
    useContext(PvPlantManagementContext);
  const { loadActivityLogList, pagination: activityLogPagination } = useContext(ActivityLogContext);
  const { loadStringList, loadOptions } = useContext(StringContext);
  const {
    pagination: stringPagination,
    filterParams: stringFilter,
    setStringStatus,
    stringStatus,
  } = useContext(StringContext);
  const { loadFilterOptions } = useContext(MaintenanceContext);
  const filter = { entityId: plantId, entityType: "PLANT", ...activityLogPagination };

useEffect(() => {
  if (status) {
    if (tab === MAINTENANCE_CATEGORY.STRING) {
      setStringStatus(status);
    }
  }
}, [status]);

  useEffect(() => {
    if (plantId) {
      loadActivityLogList(filter);
      loadPvPlant(plantId);
      loadAllDnos();
      loadAllHealthAndSafeties();
    }
  }, [activityLogPagination])

  useEffect(() => {
    if (plantId) {
      loadAllUsers();
      // loadPreventativeOptions();
      loadPvPlantTask(plantId);
    }
  }, [plantId]);

  useEffect(() => {
      if (plantId) {
        loadStringList(plantId, stringStatus);
      }
      if (plantId && !stringStatus) {
        loadStringList(plantId);
      }
  }, [stringPagination, stringFilter, stringStatus]);

  useEffect(() => {
    loadOptions();
  }, []);

  return (
    <React.Fragment>
      <Head title={`${plantId} - PV-Plant Management`} />
      <Content>
        <div style={{ overflow: "auto" }}>
          <ViewPvPlant />
        </div>
      </Content>
    </React.Fragment>
  );
};

export default withPvPlantManagementProvider(PvPlantManagementViewContainer);

import apiService from "../services/ApiService";
import { VIEW_SETTINGS } from "../constants/endpoints";

export const getTableViewSettings = async (entityType) => {
  const response = await apiService.get(VIEW_SETTINGS, {
    queryParams: entityType,
    // cache: true,
  });
  return response;
};

export const updateViewSettings = async (payload) => {
  const response = await apiService.put(VIEW_SETTINGS, {
    body: payload,
  });
  return response;
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import ViewCorrective from "../components/CorrectiveView";
import { CorrectiveContext, withCorrectiveProvider } from "../CorrectiveProvider";
import { ActivityLogContext } from "../../../../providers/ActivtiyLogProvider";

const CorrectiveMaintenanceViewContainer = (props) => {
  const { taskId, setIsOpen, editTask } = props;

  const correctiveContext = useContext(CorrectiveContext);
  const { loadTask } = correctiveContext;

  const { loadActivityLogList, pagination } = useContext(ActivityLogContext);

  const filter = { entityId: taskId, entityType: "TASK", ...pagination  };
  useEffect(() => {
    loadActivityLogList(filter);
  }, [taskId, pagination]);

  useEffect(() => {
    if (taskId) loadTask(taskId);
  }, [taskId]);

  return (
    <React.Fragment>
      <ViewCorrective mode={props.mode} setIsOpen={setIsOpen} editTask={editTask} />
    </React.Fragment>
  );
};

export default CorrectiveMaintenanceViewContainer

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import RemedialView from "../components/RemedialView";
import { RemedialContext, withRemedialProvider } from "../RemedialProvider";
import { ActivityLogContext } from "../../../../providers/ActivtiyLogProvider";

const RemedialMaintenanceViewContainer = (props) => {
  const { taskId, setIsOpen, editTask } = props;

  const remedialContext = useContext(RemedialContext);
  const { loadTask } = remedialContext;

  const { loadActivityLogList, pagination } = useContext(ActivityLogContext);

  const filter = { entityId: taskId, entityType: "TASK", ...pagination };
  useEffect(() => {
    loadActivityLogList(filter);
  }, [taskId, pagination]);

  useEffect(() => {
    if (taskId) loadTask(taskId);
  }, [taskId]);

  return (
    <React.Fragment>
      <RemedialView setIsOpen={setIsOpen} editTask={editTask} />
    </React.Fragment>
  );
};

export default RemedialMaintenanceViewContainer

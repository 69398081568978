import React, { useContext, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { MaintenanceContext } from "../../../../providers/Maintenance.provider";
import RemedialListCompact from "../components/RemedialList";
import { RemedialContext, withRemedialProvider } from "../RemedialProvider";
import { useLocation } from "react-router-dom";
import useBoolean from "../../../../hooks/useBoolean";

const RemedialMaintenanceContainer = (props) => {
  const location = useLocation().search;

  const [isMounted, setIsMounted] = useBoolean(false);

  const queryParams = new URLSearchParams(location);

  const status = queryParams.get("status");

  const { loadRemedialTasks, handleApplyFilter, filterParams, 
    getTableSettings,tasksColumnSettings } = useContext(RemedialContext);
  const { loadFilterOptions, loadAllUsers } = useContext(MaintenanceContext);

  const fetchRemedialTasksData = async () => {
    await loadRemedialTasks();
  }

  useEffect(() => {
    if (isMounted) {
      fetchRemedialTasksData()
    }
  }, [filterParams, isMounted, tasksColumnSettings]);

  useEffect(() => {
    if (status) {
      handleApplyFilter({ ...filterParams, selectedTaskStatus: [status] })
    }
    getTableSettings({ entityType: "REMEDIAL_MAINTENANCE" }, !!!status).then(() => {
      setIsMounted.on()
    })
    loadFilterOptions()
    loadAllUsers()
  }, [])


  return (
    <React.Fragment>
      <Head title="Brighter App | Remedial Maintenance" />
      <Content>
        <RemedialListCompact />
      </Content>
    </React.Fragment>
  );
};

export default withRemedialProvider(RemedialMaintenanceContainer);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import ViewUser from "../components/UserView";
import { UserManagementContext, withUserManagementProvider } from "../UserManagementProvider";
import ActivityLog from "../../../components/activity_log/components/ActivityLog"
import { ActivityLogContext } from "../../../providers/ActivtiyLogProvider"

const UserManagementViewContainer = props => {
  const params = useParams()
  const { userId } = params
  
  const { loadUser,loadAllRoles } = useContext(UserManagementContext)
  const {loadActivityLogList, pagination}= useContext(ActivityLogContext)

  useEffect(() => {
    loadUser(userId)
    loadAllRoles()
  }, [userId])

  const filter = {entityId:userId, entityType:"USER", ...pagination}
    useEffect(() => {
      loadActivityLogList(filter)
    }, [userId, pagination])
  return (
    <React.Fragment>
      <Head title={`${userId} - User Management`} />
      <Content>
        <ViewUser />
        </Content>
        <Content>
        <ActivityLog id={userId} entityType="USER"/>
      </Content>
    </React.Fragment>
  );
}

export default withUserManagementProvider(UserManagementViewContainer);
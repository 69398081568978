import { format } from "date-fns";
import React, { useContext, useState } from "react";
import { Card, Col, FormGroup, Spinner } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
} from "../../../../components/Component";
import ActivityLog from "../../../../components/activity_log/components/ActivityLog";
import Attachments from "../../../../components/attachments/Attachment";
import Check from "../../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import { TASK } from "../../../../constants/PreferenceKeys";
import Head from "../../../../layout/head/Head";
import { MaintenanceContext } from "../../../../providers/Maintenance.provider";
import { CorrectiveContext } from "../CorrectiveProvider";
import ParentTask from "./ParentTaskView";
import SubtaskList from "./subtaskComponents/SubtaskList";
import SubTransactionList from "./subtaskComponents/subEquipmentList";

const VIEW = {
  SUB_TASKS: "SUB_TASKS",
  EQUIPMENTS: "EQUIPMENTS",
};

const ViewCorrective = ({ setIsOpen, editTask, mode }) => {

  const taskContext = useContext(CorrectiveContext);
  const { filterOptions } = useContext(MaintenanceContext);
  const { currentViewTask, deleteTask } = taskContext;

  const [view, setView] = useState(VIEW.SUB_TASKS);
  let engineers = {};
  let plantNames = {};
  let priority = {};
  let status = {};
  let slas = {};
  let assetCategories = {};
  for (let i = 0; i < filterOptions.taskStatuses?.length; i++) {
    status[filterOptions.taskStatuses[i].value] = filterOptions.taskStatuses[i].label;
  }
  for (let i = 0; i < filterOptions.assignedEngineer?.length; i++) {
    engineers[filterOptions.assignedEngineer[i].value] = filterOptions.assignedEngineer[i].label;
  }
  for (let i = 0; i < filterOptions.plants?.length; i++) {
    plantNames[filterOptions.plants[i].value] = filterOptions.plants[i].label;
  }
  for (let i = 0; i < filterOptions.priorityStatuses?.length; i++) {
    priority[filterOptions.priorityStatuses[i].value] = filterOptions.priorityStatuses[i].label;
  }
  for (let i = 0; i < filterOptions.slas?.length; i++) {
    slas[filterOptions.slas[i].value] = filterOptions.slas[i].label;
  }
  for (let i = 0; i < filterOptions.assetCategories?.length; i++) {
    assetCategories[filterOptions.assetCategories[i].value] = filterOptions.assetCategories[i].label;
  }

  if (!currentViewTask) return <Spinner />;
  const handleDeleteCorrective = async (taskId) => {
    deleteTask(taskId)
      .then((res) => {
        successToast({ description: "Corrective task is deleted successfully" });
        setIsOpen(false);
      })
      .catch((e) => {
        errorToast({ description: "Error happened while deleting Corrective task" });
      });
  };

  const handleDeleteConfirmation = async (taskId) => {
    confirmationSweetAlert({
      id: taskId,
      handleConfirmation: handleDeleteCorrective,
    });
  };

  const renderParentData = (parentId) => {
    taskContext.loadTask(parentId);
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Head title="Brighter App | Corrective | View" />
      <Block className={"bg-white overflow-auto"}>
        <BlockHead size="md">
          <div id="top" className="d-md-flex justify-content-md-between">
            <BlockHeadContent>
              <BlockTitle tag="h4"> {currentViewTask.title}</BlockTitle>
              <BlockTitle tag="h6">{currentViewTask.identifier} </BlockTitle>
            </BlockHeadContent>
            <Check permission={["manageTickets"]}>
              <BlockHeadContent className="align-self-end">
                <Button
                  id="edit-corrective"
                  color="danger"
                  onClick={() => {
                    handleDeleteConfirmation(currentViewTask.id);
                  }}
                >
                  <Icon name="na" />
                  <span>Delete</span>
                </Button>
                {(currentViewTask?.plant && currentViewTask.plant.status === "ACTIVE") &&
                <Button
                  id=""
                  color="primary"
                  className={"ml-2"}
                  onClick={() => {
                    editTask(currentViewTask.id);
                  }}
                >
                  <Icon name="edit" />
                  <span>Edit</span>
                </Button>
}
              </BlockHeadContent>
            </Check>
          </div>
        </BlockHead>

        <Block>
          <div className="nk-data data-list">
            <div className="data-item">
              <div id="corrective-ticket-name" className="data-col">
                <span className="data-label">Ticket Name</span>
                <span className="data-value">{currentViewTask.title || "-"} </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div id="corrective-status" className="data-col">
                <span className="data-label">Status</span>
                <span className="data-value">{status[currentViewTask.status] || "-"}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div id="corrective-plant-name" className="data-col">
                <span className="data-label">Plant Name</span>
                <span className="data-value">{currentViewTask.plant.name || "-"}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div id="corrective-start-date" className="data-col">
                <span className="data-label">Start Date</span>
                <span className="data-value">
                  {currentViewTask?.startedAt
                    ? format(new Date(currentViewTask?.startedAt || null), "dd/MM/yyyy")
                    : "-"}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div id="corrective-resolve-date" className="data-col">
                <span className="data-label">Resolved Date</span>
                <span className="data-value">
                  {currentViewTask?.resolvedAt
                    ? format(new Date(currentViewTask?.resolvedAt || null), "dd/MM/yyyy")
                    : "-"}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div id="corrective-priority" className="data-col">
                <span className="data-label">Priority</span>
                <span className="data-value">{priority[currentViewTask.priority] || "-"}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div id="corrective-sla" className="data-col">
                <span className="data-label">SLA</span>
                <span className="data-value">{slas[currentViewTask.slaId] || "-"}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div id="corrective-asset-category" className="data-col">
                <span className="data-label">Asset Category</span>
                <span className="data-value">{assetCategories[currentViewTask.assetCategoryId] || "-"}</span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div id="corrective-assign-engineer" className="data-col">
                <span className="data-label">Assigned Engineer</span>
                <span className="data-value">
                  {currentViewTask?.assignedEngineer
                    ?.map((item, key) => {
                      return `${item.firstName} ${item.lastName}`;
                    })
                    .join(", ") || "-"}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div id="corrective-field-engineer" className="data-col">
                <span className="data-label">Field Engineer</span>
                <span className="data-value">
                  {currentViewTask?.fieldEngineer
                    ?.map((item, key) => {
                      return `${item.firstName} ${item.lastName}`;
                    })
                    .join(", ") || "-"}
                </span>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div id="corrective-parent-task" className="data-col">
                <div className="data-label">Parent Task</div>
                <a href="#top" onClick={() => renderParentData(currentViewTask.parentTask?.id)} className="data-value">
                  {currentViewTask.parentTask?.title || "-"}
                </a>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Comment</span>
                <span dangerouslySetInnerHTML={{__html:currentViewTask?.comment}} className="data-value text-break test-editor"/>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            <div className="data-item">
              <div className="data-col">
                <span className="data-label" id="discription-style">Description</span>
                <div dangerouslySetInnerHTML={{__html: currentViewTask?.description || "-"}} className="data-value test-editor"/>
              </div>
              <div className="data-col data-col-end"></div>
            </div>
            {
              <Col lg="12 pt-2">
                <FormGroup className="form-group">
                  <div className="form-control-wrap">
                    <Attachments
                      module={TASK}
                      id={currentViewTask.id}
                      attachments={currentViewTask.attachment}
                      subModule={"view"}
                    />
                  </div>
                </FormGroup>
              </Col>
            }
            {currentViewTask.parentTask && (
              <>
                <Col lg="12" className="mt-2">
                  <Block size="lg" className="mt-2">
                    <Card className="card-bordered p-2">
                      <div>
                        <BlockTitle id="follow-up-task" tag="h6" className="mb-1">
                          Parent Task
                        </BlockTitle>

                        <ParentTask
                          onClick={() => renderParentData(currentViewTask.parentTask?.id)}
                          currentParentTask={currentViewTask.parentTask}
                          parentId={currentViewTask.parentId}
                          taskPlant={currentViewTask.plant}
                        />
                      </div>
                    </Card>
                  </Block>
                </Col>
              </>
            )}
            {(currentViewTask && currentViewTask?.plant.status === "ACTIVE") && (
              <>
                <Col lg="12" className="mt-2">
                  <Block size="lg" className="mt-2">
                    <Card className="card-bordered p-2">
                      <div>
                        <BlockTitle id="follow-up-task" tag="h6" className="mb-1">
                          Follow-up Task
                        </BlockTitle>

                        <SubtaskList
                          mode={mode}
                          currentUser={currentViewTask.title}
                          parentId={currentViewTask.id}
                          plantId={currentViewTask.plantId}
                        />
                      </div>
                    </Card>
                  </Block>
                  <Block size="lg" className="mt-2">
                    <Card className="card-bordered p-2">
                      <div>
                        <BlockTitle id="equipment-schedule" tag="h6" className="mb-1">
                          Equipment
                        </BlockTitle>
                        <SubTransactionList currentViewTask={currentViewTask} />
                      </div>
                    </Card>
                  </Block>
                </Col>
              </>
            )}
          </div>
          <div className="m-2 ml-3">
            <small class="text-muted">
              CreatedAt: {format(new Date(currentViewTask?.created_at || null), "dd/MM/yyyy HH:mm")}{" "}
            </small>
          </div>
          <div className="m-2 ml-3">
            <small class="text-muted">
              UpdatedAt: {format(new Date(currentViewTask?.updated_at || null), "dd/MM/yyyy HH:mm")}{" "}
            </small>
          </div>
        </Block>
        <Block>
          <ActivityLog id={currentViewTask.id} entityType="TASK" />
        </Block>
      </Block>
    </div>
  );
};
export default ViewCorrective;

import { openDB } from 'idb';
import { createHash } from 'crypto';

class IndexCacheService {
  constructor() {
    if (IndexCacheService.instance) {
      return IndexCacheService.instance;
    }

    IndexCacheService.instance = this;
    this.dbStatus = 'INITIAL';
    this.dbName = 'bge_api_caches';
    this.storeName = 'bge_api_responses';
    this.maxEntries = 30;
    this.maxAge = 2 * 60 * 1000; // 2 minutes in milliseconds
    this.initDB();

    const _that = this;

    if (!this.intervalId) {
      // Run cleanup every minute
      this.intervalId = setInterval(function () {
        return _that.enforceCacheLimit();
      }, 60 * 1000);
    }

  }

  async initDB() {
    if (this.dbStatus !== 'INITIAL') {
      return;
    }

    this.dbStatus = 'INITIATING';
    const _that = this;

    try {
      // Wait for any existing connections to close
      if (this.db) {
        this.db.close();
      }

      this.db = await openDB(this.dbName, 3, { // Increment version to force upgrade
        upgrade(db, oldVersion) {
          // Delete old store to ensure clean slate
          if (db.objectStoreNames.contains(_that.storeName)) {
            db.deleteObjectStore(_that.storeName);
          }
          // Create store with timestamp index
          const store = db.createObjectStore(_that.storeName);
          store.createIndex('timestamp', 'timestamp', { unique: false });
        },
      });

      // console.log(`DB STATUS CONNECTED`);
      this.dbStatus = 'CONNECTED';
    } catch (e) {
      // console.log('IndexDB initialization error:', e);
      this.dbStatus = 'FAILED';
      throw e;
    }
  }

  generateCacheKey(url, options) {
    const keyObj = {
      url,

      // Query params and pathParams are already encoded in the URL, so ignoring;
      // queryParams: options.queryParams || {},
      // pathParams: options.pathParams || {},
    };

    if (options.body) {
      keyObj.body = options.body;
    }

    const hash = createHash('sha256')
    const cacheKey = hash.update(JSON.stringify((keyObj))).digest('hex');
    return cacheKey;
  }

  async getCachedResponse(url, options) {
    await this.initDB();
    const cacheKey = this.generateCacheKey(url, options);

    const cachedData = await this.db.get(this.storeName, cacheKey);
    if (cachedData) {
      await this.db.put(this.storeName, {
        ...cachedData,
        timestamp: Date.now()
      }, cacheKey);
      return cachedData.data;
    }
    return null;
  }

  async setCachedResponse(url, options, response) {
    await this.initDB();
    const cacheKey = this.generateCacheKey(url, options);

    const entry = {
      data: response,
      timestamp: Date.now()
    };

    await this.enforceCacheLimit();

    await this.db.put(this.storeName, entry, cacheKey);
  }

  async enforceCacheLimit() {
    await this.initDB();
    const tx = this.db.transaction(this.storeName, 'readwrite');
    const store = tx.objectStore(this.storeName);
    const timestampIndex = store.index('timestamp');

    // Get all entries
    const entries = await timestampIndex.getAllKeys();
    const now = Date.now();

    // Check all entries and collect old ones
    const oldEntries = [];
    for (const key of entries) {
      const entry = await store.get(key);
      if ((now - entry.timestamp) > this.maxAge) {
        oldEntries.push(key);
      }
    }

    await Promise.all(oldEntries.map(key => store.delete(key)));

    // Continue with existing size-based cleanup
    const count = await store.count();
    if (count >= this.maxEntries) {
      const oldestEntries = await timestampIndex.getAllKeys(undefined, {
        count: count - this.maxEntries + 1
      });

      await Promise.all(oldestEntries.map(key => store.delete(key)));
    }
  }

  async invalidateCache(url) {
    await this.initDB();
    const keys = await this.db.getAllKeys(this.storeName);
    for (const key of keys) {
      const keyObj = JSON.parse(key);
      if (keyObj.url === url) {
        await this.db.delete(this.storeName, key);
      }
    }
  }

  async clearCache() {
    await this.initDB();
    await this.db.clear(this.storeName);
  }
}

export default new IndexCacheService();

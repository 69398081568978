import React, { useContext, useEffect } from "react";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import { MaintenanceContext } from "../../../../providers/Maintenance.provider";
import CorrectiveListCompact from "../components/CorrectiveList";
import { CorrectiveContext, withCorrectiveProvider } from "../CorrectiveProvider";
import { useLocation } from "react-router-dom";
import useBoolean from "../../../../hooks/useBoolean";
import { updateViewSettings } from "../../../../repositories/ViewRepository";

const CorrectiveMaintenanceContainer = (props) => {
  const location = useLocation().search;

  const [isMounted, setIsMounted] = useBoolean(false);

  const queryParams = new URLSearchParams(location);

  const status = queryParams.get("status");

  const { handleApplyFilter, filterParams, getTableSettings, loadCorrectiveTasks ,tasksColumnSettings,} = useContext(CorrectiveContext);
  const { loadFilterOptions, loadAllUsers } = useContext(MaintenanceContext);

  const fetchCorrectiveTasksData = async () => {
    loadCorrectiveTasks();
  };

  useEffect(() => {
    if (isMounted) {
      fetchCorrectiveTasksData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams, isMounted,tasksColumnSettings]);


  useEffect(() => {
    if (status) {
      handleApplyFilter({ ...filterParams, selectedTaskStatus: [status] });
    }

    getTableSettings({ entityType: "CORRECTIVE_MAINTENANCE", }, !!!status).then(() => {
      setIsMounted.on();
    });

    loadFilterOptions();
    loadAllUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

      useEffect(() => {
          if (isMounted) {
              updateViewSettings(tasksColumnSettings)
          }
      }, [tasksColumnSettings])

  return (
    <React.Fragment>
      <Head title="Brighter App | Corrective Maintenance" />
      <Content>
        <CorrectiveListCompact />
      </Content>
    </React.Fragment>
  );
};

export default withCorrectiveProvider(CorrectiveMaintenanceContainer);

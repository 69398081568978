/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Row, Col, FormGroup, Form, Label, Spinner, Card } from "reactstrap";
import { Block, PreviewCard, Button, RSelect, BlockTitle } from "../../../../components/Component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useBoolean from "../../../../hooks/useBoolean";
import { MaintenanceContext } from "../../../../providers/Maintenance.provider";
import { CorrectiveContext } from "../CorrectiveProvider";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import Attachments from "../../../../components/attachments/Attachment";
import { TASK } from "../../../../constants/PreferenceKeys";
import SubtaskList from "./subtaskComponents/SubtaskList";
import CreateFormAttachments from "../../../../components/attachments/CreateFormAttachment";
import { sortOption } from "../../../../utils/Utils";
import Head from "../../../../layout/head/Head";
import SubTransactionList from "./subtaskComponents/subEquipmentList";
import Skeleton from "react-loading-skeleton";
import { format } from "date-fns";
import { TextEditor } from "../../../../components/ReactQuillEditor";

const createSchema = yup.object({
  title: yup.string().required().default(""),
  priority: yup.string().notRequired(),
  plantId: yup.number().required().default(""),
  status: yup.string().trim().required().default(""),
  startedAt: yup.date().required().default(""),
  resolvedAt: yup.date().min(yup.ref("startedAt")).notRequired(),
  assignedToIds: yup.array().of(yup.number()).min(1).required(),
  fieldEngineerIds: yup.array().of(yup.number()).min(1).required(),
  description: yup.string().notRequired().nullable(),
  slaId: yup.number().notRequired().default(),
  assetCategoryId: yup.number().notRequired().default(),
  parentId: yup.number().notRequired().nullable(),
  labourHours: yup.number().notRequired().nullable(),
  comment: yup.string().notRequired().nullable(),
});

const updateSchema = yup.object({
  title: yup.string().required().default(""),
  priority: yup.string().notRequired().default(""),
  plantId: yup.number().required().default(""),
  status: yup.string().trim().required().default(""),
  startedAt: yup.date().required().default(""),
  resolvedAt: yup.date().min(yup.ref("startedAt")).nullable(),
  assignedToIds: yup.array().of(yup.number()).min(1).required(),
  fieldEngineerIds: yup.array().of(yup.number()).min(1).required(),
  description: yup.string().notRequired().nullable(),
  slaId: yup.number().notRequired().default(),
  assetCategoryId: yup.number().notRequired().default(),
  parentId: yup.number().notRequired().nullable(),
  labourHours: yup.number().notRequired().nullable(),
  comment: yup.string().notRequired().nullable(),
});

const CorrectiveForm = (props) => {
  const { plantId: currentPlantId } = props
  const { filterOptions, createCorrective, createLoading } = useContext(MaintenanceContext);

  const correctiveContext = useContext(CorrectiveContext);
  const { correctivetasks, loadAllCorrectiveTasks, updateLoading, loadCorrectiveTasks, tasksColumnSettings } = correctiveContext;

  const [isEditMode, updateEditMode] = useBoolean(false);
  const [attachments, setAttachments] = useState([]);
  const [plantId, setPlantId] = useState();

  const userFormRef = useForm({
    resolver: yupResolver(props.taskId ? updateSchema : createSchema),
  });
  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = userFormRef;

  useEffect(() => {
    if (props.plantId) {
      onChangePlant(parseInt(props.plantId));
    }
    if (!props.currentViewTask) {
      reset({ startedAt: new Date(), status: "OPEN", plantId: props.plantId });
    }

    if (props.currentViewTask) {
      //to get the parent task dropdown based on plantId and currentTask
      const filter = { plantId: props.currentViewTask.plantId, taskId: props.currentViewTask.id };
      loadAllCorrectiveTasks(filter);

      setPlantId(props.currentViewTask.plantId);

      if (props.currentViewTask.resolvedAt === null) {
      }
      reset({ ...props.currentViewTask, comment: props.currentViewTask.comment });
      setAttachments(
        props.currentViewTask.attachment.map((item) => {
          return { ...item.files, status: "EXISTING" };
        })
      );
      updateEditMode.on();
    }
  }, [props.currentViewTask, props.plantId]);

  const handleFormSubmit = (formData) => {

    // Only date is required so removed timestamp which double converts to UTC;
    if (formData.resolvedAt) {
      formData.resolvedAt = format(formData.resolvedAt, 'yyyy-MM-dd')
    }
    if (formData.startedAt) {
      formData.startedAt = format(formData.startedAt, 'yyyy-MM-dd')
    }
    //to remove html tags
    const regex = /(<([^>]+)>)/gi;
    if (formData.comment) {
      formData.comment = (formData.comment).replace(regex, "");
    }
    if (formData.description) {
      formData.description = (formData.description).replace(regex, "");
    }

    if (props.taskId) {
      props
        .updateTask(props.currentViewTask.id, { ...formData, attachments })
        .then((res) => {
          props.viewTask(props.currentViewTask.id);
          loadCorrectiveTasks({plantId: currentPlantId})
          successToast({ description: "Successfully Updated" });
        })
        .catch((e) => {
          errorToast({ description: "Error happened while updating Corrective maintenance" });
        });
    } else {
      createCorrective({ ...formData, attachments })
        .then((res) => {
          successToast({ description: "Successfully Created" });
          loadCorrectiveTasks({plantId: currentPlantId})
          if (props.onSuccessfulModal) props.onSuccessfulModal();
        })
        .catch((e) => {
          errorToast({ description: "Error happened while creating Corrective maintenance" });
        });
    }
  };
  const attachmentFiles = (files) => {
    if (files) {
      setAttachments(files);
    }
  };
  let engineers = [];
  filterOptions.plants?.forEach((item) => {
    if (item.value === plantId) {
      for (let i = 0; i < filterOptions.assignedEngineer.length; i++) {
        if (item.fieldEngineerIds?.includes(filterOptions.assignedEngineer[i].value)) {
          engineers.push(filterOptions.assignedEngineer[i]);
        }
      }
    }
  });

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button
        id="add-correctiveForm"
        color="primary "
        size="lg"
        isLoading={createLoading}
        onClick={createLoading ? null : handleSubmit(handleFormSubmit)}
      >
        {createLoading ? <Spinner size="sm" /> : "Add"}
      </Button>
    );
  };
  // Object.keys(touchedFields).length
  const renderEditButton = () => {
    return (
      <>
        <Button
          id="save-correctiveForm"
          color="primary"
          size="lg"
          className="mr-2"
          isLoading={updateLoading}
          onClick={updateLoading ? null : handleSubmit(handleFormSubmit)}
        >
          {updateLoading ? <Spinner size={"sm"} /> : "Save Information"}
        </Button>

        <Button
          onClick={() => {
            props.viewTask(props.currentViewTask.id);
          }}
          id="save-correctiveForm"
          size="lg"
          color="outline-primary"
        >
          Cancel
        </Button>
      </>
    );
  };

  const onChangeParentTask = (value) => {
    setValue("parentId", value);
  };

  const onChangePlant = (value) => {
    setPlantId(value);
    setValue("plantId", value, { shouldValidate: true });
    setValue("fieldEngineerIds", []);
    setValue("assignedToIds", []);
  };

  const onChangeDate = (date) => {
    setValue("startedAt", date);
  };
  const renderSaveButton = () => {
    if (isEditMode) {
      return renderEditButton();
    } else {
      return renderCreateButton();
    }
  };

  return (
    <div className="overflow-auto h-max-550px">
      <Head title={props.mode === "EDIT" ? "Brighter App | Corrective | Edit" : "Brighter App | Corrective | Create"} />
      <Block size="lg">
        <PreviewCard>
          {props.mode !== "EDIT" || props?.currentViewTask ? (
            <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
              <Row className="g-4">
                <Col lg="6">
                  <FormGroup>
                    <Label className="form-label" htmlFor="fv-full-name">
                      <span style={{ color: "indianred" }}>&#42;</span>Task Name
                    </Label>
                    <div className="form-control-wrap">
                      <input {...register("title")} type="text" name="title" className="form-control" />
                    </div>
                    {errors.title && (
                      <span className="invalid" style={{ color: "red" }}>
                        Title is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label className="form-label" htmlFor="fv-full-name">
                      Priority
                    </Label>
                    <Controller
                      control={control}
                      name="priority"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = filterOptions.priorityStatuses;
                        const selectedValue = options.find((e) => e.value === field.value);
                        return (
                          <RSelect
                            id="priority-correctiveForm-select"
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            options={options}
                            onChange={(o) => setValue("priority", o.value)}
                          />
                        );
                      }}
                    />
                    {errors?.priority && (
                      <span className="invalid" style={{ color: "red" }}>
                        Priority is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label className="form-label" htmlFor="fv-full-name">
                      SLA
                    </Label>
                    <Controller
                      control={control}
                      name="slaId"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = filterOptions.slas;
                        const selectedValue = options.find((e) => e.value === field.value);
                        return (
                          <RSelect
                            id="sla=correctiveForm-select"
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            options={sortOption(options)}
                            placeholder="Select the SLA"
                            onChange={(o) => setValue("slaId", o.value)}
                          />
                        );
                      }}
                    />
                    {errors?.slaId && (
                      <span className="invalid" style={{ color: "red" }}>
                        SLA is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="form-group">
                    <label className="form-label">
                      <span style={{ color: "indianred" }}>&#42;</span>Status
                    </label>
                    <Controller
                      control={control}
                      name="status"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = filterOptions.taskStatuses;
                        const selectedValue = options.find((e) => e.value === field.value);
                        return (
                          <RSelect
                            is="satus-correctiveForm-select"
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            options={options}
                            defaultValue={options[0]}
                            onChange={(o) => setValue("status", o.value, { shouldValidate: true })}
                          />
                        );
                      }}
                    />
                    {errors?.status && (
                      <span className="invalid" style={{ color: "red" }}>
                        Status is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
                {props.plantId && (
                  <Col lg="6">
                    <FormGroup>
                      <Label className="form-label" htmlFor="fv-full-name">
                        <span style={{ color: "indianred" }}>&#42;</span>Plant Name
                      </Label>
                      <Controller
                        control={control}
                        name="plantId"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          const options = filterOptions.plants;
                          const selectedValue = options.find((e) => e.value === parseInt(props.plantId));
                          return <RSelect {...field} ref={ref} value={selectedValue} isDisabled />;
                        }}
                      />
                      {errors?.plantId && (
                        <span className="invalid" style={{ color: "red" }}>
                          Plant name is required
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                )}
                {!props.plantId && (
                  <Col lg="6">
                    <FormGroup>
                      <Label className="form-label" htmlFor="fv-full-name">
                        <span style={{ color: "indianred" }}>&#42;</span>Plant Name
                      </Label>
                      <Controller
                        control={control}
                        name="plantId"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          const options = filterOptions.plants;
                          const selectedValue = options.find((e) => e.value === field.value);
                          return (
                            <RSelect
                              id="plantname-correctiveForm-select"
                              {...field}
                              ref={ref}
                              value={selectedValue}
                              options={sortOption(options)}
                              onChange={(o) => {
                                onChangePlant(o.value);
                              }}
                            />
                          );
                        }}
                      />
                      {errors?.plantId && (
                        <span className="invalid" style={{ color: "red" }}>
                          Plant name is required
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                )}
                <Col lg="6">
                  <FormGroup className="form-group">
                    <label className="form-label">
                      <span style={{ color: "indianred" }}>&#42;</span>Field Engineer
                    </label>
                    <div>
                      <Controller
                        control={control}
                        name="fieldEngineerIds"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          const options = engineers;
                          const selectedValue = options.filter((e) => field.value && field.value.includes(e.value));
                          return (
                            <RSelect
                              id="fieldEngineer-correctiveForm-select"
                              {...field}
                              ref={ref}
                              value={selectedValue}
                              isMulti
                              options={sortOption(options)}
                              onChange={(o) =>
                                setValue(
                                  "fieldEngineerIds",
                                  o.map((item) => item.value),
                                  { shouldValidate: true }
                                )
                              }
                            />
                          );
                        }}
                      />
                    </div>
                    {errors?.fieldEngineerIds && (
                      <span className="invalid" style={{ color: "red" }}>
                        Field Engineer is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label className="form-label" htmlFor="fv-full-name">
                      Start Date
                    </Label>
                    <div className="form-control-wrap">
                      <Controller
                        control={control}
                        name="startedAt"
                        render={({ field }) => {
                          return (
                            <DatePicker
                              id="startdate--correctiveForm-select"
                              ref={field.ref}
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              dateFormat="dd/MM/yyyy HH:mm"
                              selected={field.value ? field.value : new Date()}
                              onChange={(date) => onChangeDate(date)}
                              className="form-control date-picker"
                            />
                          );
                        }}
                      />
                    </div>
                    {errors?.startedAt && (
                      <span className="invalid" style={{ color: "red" }}>
                        Start Date is required
                      </span>
                    )}
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup className="form-group">
                    <label className="form-label">
                      <span style={{ color: "indianred" }}>&#42;</span>Assigned To
                    </label>
                    <Controller
                      control={control}
                      name="assignedToIds"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = engineers;
                        const selectedValue = options.filter((e) => field.value && field.value.includes(e.value));
                        return (
                          <RSelect
                            id="assignedto-correctiveForm-select"
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            isMulti
                            options={sortOption(options)}
                            onChange={(o) => {
                              setValue(
                                "assignedToIds",
                                o.map((item) => item.value),
                                { shouldValidate: true }
                              );
                            }}
                          />
                        );
                      }}
                    />
                    {errors?.assignedToIds && (
                      <span className="invalid" style={{ color: "red" }}>
                        Assigned To is required
                      </span>
                    )}
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup className="form-group">
                    <label className="form-label">Resolved Date</label>
                    <div className="form-control-wrap">
                      <Controller
                        control={control}
                        name="resolvedAt"
                        render={({ field, ref }) => {
                          return (
                            <DatePicker
                              id="resolvedat-correctiveForm-select"
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              dateFormat="dd/MM/yyyy HH:mm"
                              selected={field.value ? field.value : 0}
                              onChange={(date) => {
                                setValue("resolvedAt", date, { shouldValidate: true });
                              }}
                              className="form-control date-picker"
                            />
                          );
                        }}
                      />
                    </div>
                    {errors?.resolvedAt && (
                      <span className="invalid" style={{ color: "red" }}>
                        {errors?.resolvedAt.message.includes("later") &&
                          `Resolved Date should be greater than Start Date`}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="form-group">
                    <label className="form-label">Asset Category</label>
                    <Controller
                      control={control}
                      name="assetCategoryId"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = filterOptions.assetCategories;
                        const selectedValue = options.find((e) => e.value === field.value);
                        return (
                          <RSelect
                            id="assercategory-correctiveForm-select"
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            options={sortOption(options)}
                            placeholder="Select the Asset category "
                            onChange={(o) => setValue("assetCategoryId", o.value)}
                          />
                        );
                      }}
                    />
                    {errors?.assetCategoryId && (
                      <span className="invalid" style={{ color: "red" }}>
                        Asset Category is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label className="form-label" htmlFor="labourHours">
                      Labour Hours
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        id="labourhour--correctiveForm"
                        {...register("labourHours", {
                          setValueAs: (v) => (v ? parseInt(v) : parseInt(0)),
                        })}
                        type="number"
                        name="labourHours"
                        className="form-control"
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label className="form-label" htmlFor="fv-full-name">
                      Parent Task
                    </Label>
                    <Controller
                      control={control}
                      name="parentId"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = correctivetasks;
                        const selectedValue = options.find((e) => e.value === field.value);
                        return (
                          <RSelect
                            id="parentid-correctiveForm"
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            options={options}
                            onChange={(o) => {
                              onChangeParentTask(o.value);
                            }}
                          />
                        );
                      }}
                    />
                    {errors?.parentId && <span className="invalid"></span>}
                  </FormGroup>
                </Col>
                <Col lg="12" md="12">
                  <FormGroup className="form-group">
                    <label className="form-label">Comment</label>
                    <div className="form-control-wrap">
                      <Controller
                        control={control}
                        name="comment"
                        render={({ field, ref }) => {
                          return (
                            <TextEditor
                              field={field}
                              setValue={setValue}
                              fieldName={"comment"}
                            />
                          );
                        }}
                      />
                      {errors.comment && <p className="invalid">This field is required</p>}
                    </div>
                  </FormGroup>
                </Col>
                <Col lg="12" md="12">
                  <FormGroup className="form-group">
                    <label className="form-label">Description</label>
                    <div className="form-control-wrap">
                      <Controller
                        control={control}
                        name="description"
                        render={({ field, ref }) => {
                          return (
                            <TextEditor
                              field={field}
                              setValue={setValue}
                              fieldName={"description"}
                            />

                          );
                        }}
                      />
                      {errors.description && <p className="invalid">This field is required</p>}
                    </div>
                  </FormGroup>
                </Col>
                {isEditMode && props.currentViewTask && (
                  <Col lg="12">
                    <FormGroup className="form-group">
                      <div className="form-control-wrap">
                        <Attachments
                          module={TASK}
                          id={props.currentViewTask.id}
                          attachments={props.currentViewTask.attachment}
                          subModule={""}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                )}
                {!isEditMode && (
                  <Col lg="12">
                    <FormGroup className="form-group">
                      <div className="form-control-wrap">
                        <CreateFormAttachments attachmentFiles={attachmentFiles} />
                      </div>
                    </FormGroup>
                  </Col>
                )}
                {isEditMode && props.currentViewTask && props.currentViewTask?.plant.status === "ACTIVE" && (
                  <Col lg="12" className="mt-2">
                    <Block size="lg" className="mt-2">
                      <Card className="card-bordered p-2">
                        <div>
                          <BlockTitle id="follow-up-task" tag="h6" className="mb-1">
                            Follow-up Task
                          </BlockTitle>

                          <SubtaskList
                            mode={props.mode}
                            currentUser={props.currentViewTask.title}
                            parentId={props.currentViewTask.id}
                            plantId={props.currentViewTask.plantId}
                          />
                        </div>
                      </Card>
                    </Block>
                    <Block size="lg" className="mt-2">
                      <Card className="card-bordered p-2">
                        <div>
                          <BlockTitle id="equipment-schedule" tag="h6" className="mb-1">
                            Equipment
                          </BlockTitle>
                          <SubTransactionList currentViewTask={props.currentViewTask} />
                        </div>
                      </Card>
                    </Block>
                  </Col>
                )}
                <Col xl="12" className="d-flex justify-content-end">
                  {renderSaveButton()}
                </Col>
              </Row>
            </Form>
          ) : (
            <Skeleton count={15} width={"100"} />
          )}
        </PreviewCard>
      </Block>
    </div>
  );
};

export default CorrectiveForm;

import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import * as yup from "yup";
import { Block, Button, Icon, PreviewCard } from "../../../../components/Component";
import ActivityLog from "../../../../components/activity_log/components/ActivityLog";
import Check from "../../../../components/check_roles/check";
import { confirmationSweetAlert } from "../../../../components/sweetAlert/SweetAlert";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import { PREVENTATIVE_MASTERS_PAGE } from "../../../../constants/routes";
import Head from "../../../../layout/head/Head";
import { ActivityLogContext } from "../../../../providers/ActivtiyLogProvider";
import { MODE } from "../../../maintenance/preventative/constants";
import { PreventativeContext } from "../PreventativeProvider";
import {
  createPreventativeMasterList,
  deletePreventativeMasterList,
  getPreventativeMasterList,
  updatePreventativeMasterList,
} from "../PreventativeRepository";

const PreventiveMaintenanceForm = (props) => {
  const preventativeContext = useContext(PreventativeContext);
  const { filterParams, loadPreventativeMasterLists } = preventativeContext;
  const [currentTask, setCurrentTask] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isArchived, setIsArchived] = useState(false);

  const { loadActivityLogList, pagination } = useContext(ActivityLogContext);
  const history = useHistory();
  const createSchema = yup.object({
    name: yup.string().required(),
    description: yup.string().required(),
    isArchived: yup.boolean(),
  });

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const taskFormRef = useForm({
    resolver: yupResolver(createSchema),
  });

  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    getValues,
  } = taskFormRef;

  const loadActivity = () => {
    const filter = { entityId: props.taskId, entityType: "PREVENTATIVE_MASTER_TASK", ...pagination };
    loadActivityLogList(filter);
  };
  useEffect(() => {
    if (props.taskId) {
      loadTask(props.taskId);
      loadActivity();
    }
  }, [props.taskId, pagination]);

  const loadTask = async (taskId) => {
    try {
      const response = await getPreventativeMasterList(taskId);
      setCurrentTask(response.preventativeMasterList);
      setIsArchived(response.preventativeMasterList.isArchived);
      reset({ ...response.preventativeMasterList });
    } catch (e) {
      // CAPTURE EXCEPTION
    }
  };

  const handleFormSubmit = async (formData) => {
    if (props.mode === MODE.CREATE) {
      try {
        setIsLoading(true);
        const response = await createPreventativeMasterList({ ...formData, isArchived });
        props.onSuccessfulModal();
        successToast({ description: "Successfully Created" });
        setIsLoading(false);
        history.push(PREVENTATIVE_MASTERS_PAGE.replace(":preventiveMasterListId", response.preventativeMasterList.id));
      } catch (err) {
        errorToast({ description: "Error happened while creating Preventative Master List" }, "hh");
        setIsLoading(false);
      }
    } else {
      try {
        setIsLoading(true);
        await updatePreventativeMasterList(props.taskId, { ...formData, isArchived });
        loadPreventativeMasterLists(filterParams);
        loadActivity();
        successToast({ description: "Successfully Updated" });
        setIsLoading(false);
        props.setMode(MODE.VIEW);
      } catch (err) {
        errorToast({ description: "Error happened while updating Preventative Master List" });
        setIsLoading(false);
      }
    }
  };
  const deleteFunc = async (taskId) => {
    try {
      await deletePreventativeMasterList(taskId);
      props.onSuccessfulModal();
      successToast({ description: "Successfully Deleted" });
    } catch (err) {
      errorToast({ description: "Error happened while deleting Preventative Master List" });
    }
  };
  const handleDeleteConfirmation = async (taskId) => {
    confirmationSweetAlert({
      id: taskId,
      handleConfirmation: deleteFunc,
    });
  };

  return (
    <React.Fragment>
      <div className="overflow-auto h-max-450px">
        <Head
          title={
            props.mode === MODE.CREATE
              ? "Brighter App | Preventative Master | Create"
              : "Brighter App | Preventative Master | Edit"
          }
        />
        <Block size="lg">
          <PreviewCard>
            <Check permission={["manageTickets"]}>
              {props.mode === MODE.VIEW ? (
                <Row className="d-flex justify-content-end mb-2">
                  <Col className="d-flex justify-content-end">
                    <Button color="danger" size="md" onClick={() => handleDeleteConfirmation(currentTask.id)}>
                      <Icon name="na" />
                      <span>Delete</span>
                    </Button>
                    <Button className="ml-2" color="primary" size="md" onClick={() => props.setMode(MODE.EDIT)}>
                      <Icon name="edit" />
                      <span>Edit</span>
                    </Button>
                  </Col>
                </Row>
              ) : null}
            </Check>
            <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <Label className="form-label" htmlFor="fv-full-name">
                      <span style={{ color: "indianred" }}>&#42;</span>Name
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        {...register("name")}
                        type="text"
                        name="name"
                        className="form-control"
                        disabled={props.mode === MODE.VIEW}
                      />
                    </div>
                    {errors.name && (
                      <span className="invalid" style={{ color: "red" }}>
                        Name is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <FormGroup>
                    <Label className="form-label" htmlFor="fv-full-name">
                      <span style={{ color: "indianred" }}>&#42;</span>Description
                    </Label>
                    <div className="form-control-wrap">
                      <textarea
                        {...register("description")}
                        name="description"
                        className="form-control form-control-sm"
                        disabled={props.mode === MODE.VIEW}
                      />
                    </div>
                    {errors.description && (
                      <span className="invalid" style={{ color: "red" }}>
                        Description is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col size="6">
                  <div className="custom-control custom-control-sm custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input form-control"
                      id="isArchived-form"
                      onChange={(e) => {
                        setIsArchived(e.target.checked);
                      }}
                      checked={isArchived}
                    />
                    <label className="custom-control-label" htmlFor="isArchived-form">
                      Archived
                    </label>
                  </div>
                </Col>
              </Row>
              {props.mode !== MODE.VIEW ? (
                <Row className="d-flex justify-content-end mt-4">
                  <Col className="d-flex justify-content-end">
                    {props.mode === MODE.EDIT ? (
                      <Button
                        color="danger"
                        size="md"
                        onClick={() => {
                          props.setIsOpen(false);
                        }}
                      >
                        Cancel
                      </Button>
                    ) : null}
                    <Button
                      className="ml-3"
                      color="primary"
                      size="md"
                      onClick={handleSubmit(handleFormSubmit)}
                      isLoading={isLoading}
                    >
                      {props.mode === MODE.CREATE ? "Create" : "Save"}
                    </Button>
                  </Col>
                </Row>
              ) : null}
            </Form>
            {props.taskId ? <ActivityLog id={props.taskId} entityType="PREVENTATIVE_MASTER_TASK" /> : null}
          </PreviewCard>
        </Block>
      </div>
    </React.Fragment>
  );
};
export default PreventiveMaintenanceForm;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { updateViewSettings } from "../../../repositories/ViewRepository";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { PreventativeTaskContext, withPreventativeTaskProvider } from "../../maintenance/preventative/preventativeTasks/PreventativeTaskProvider";
import { MaintenanceContext } from "../../../providers/Maintenance.provider";
import useBoolean from "../../../hooks/useBoolean";
import PlantPreventiveList from "../components/PvPlantPreventiveMaintananceList";
import { MAINTENANCE_CATEGORY } from "../../../constants/constants";

const PvPlantPreventativeContainer = (props) => {
  const location = useLocation().search;
  const tab = new URLSearchParams(location).get("tab");
  const dashboard = new URLSearchParams(location).get("dashboard");
  //   const plantId = new URLSearchParams(location).get("plantId");
  const queryParams = new URLSearchParams(location);
  const { getTableSettings, handleApplyFilter, filterParams, loadOptions, loadPreventativeTasks, tasksColumnSettings, preventativeTasks, setPreventativeTasks, initialFilterParams } = useContext(PreventativeTaskContext);
  const { loadAllUsers } = useContext(MaintenanceContext);
  const { plantId } = props

  const [isMounted, setIsMounted] = useBoolean(false);
  const status = queryParams.get("status");

  const fetchPreventativeTasksData = async () => {
    await loadPreventativeTasks({ plantId })
  };

  useEffect(() => {
    if (isMounted) {
      fetchPreventativeTasksData()
    }
  }, [filterParams, isMounted, tasksColumnSettings])


  useEffect(() => {
    if (tab === MAINTENANCE_CATEGORY.PREVENTATIVE) {
      if (status) {
        handleApplyFilter({ ...initialFilterParams, selectedTaskStatus: [status], dashboard: dashboard });
      }
      getTableSettings({ entityType: "PREVENTATIVE_PLANT", }, !!!status).then(() => {
        setIsMounted.on();
      });
      loadOptions();
    }
  }, [tab]);

  useEffect(() => {
    if (isMounted) {
      updateViewSettings(tasksColumnSettings)
    }
  }, [tasksColumnSettings])

  return (
    <React.Fragment>
      <Head title="Brighter App | Preventative Task" />
      <Content>
        <PlantPreventiveList
          plantId={props.plantId}
          isArchived={props.isArchived}
          plantStatus={props.plantStatus}
        />
      </Content>
    </React.Fragment>
  );
};

export default withPreventativeTaskProvider(PvPlantPreventativeContainer);

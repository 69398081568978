/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useState } from "react";
import { Row, Col, FormGroup, Form, Label, CardBody, CardText } from "reactstrap";
import { Block, PreviewCard, Button } from "../../../../components/Component";
import { exportTask } from "../CorrectiveRepository";
import download from "js-file-download";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";

export const TASK_FIELDS = [
  {
    label: "Ticket Name",
    value: "title",
    field_value: "title"
  },

  {
    label: "Priority",
    value: "priority",
    field_key: "priority"
  },
  {
    label: "Identifier or Acronym",
    value: "identifier",
    field_key: "identifier"
  },

  {
    label: "Started At",
    value: "startedAt",
    field_key: "startedAt"
  },
  {
    label: "Resolved At",
    value: "resolvedAt",
    field_key: "resolvedAt"
  },
  {
    label: "Status",
    value: "status",
    field_key: "status"
  },
  {
    label: "Category",
    value: "category",
    field_key: "category"
  },
  {
    label: "Asset Category",
    value: "assetCategory.name",
    field_key: "assetCategory"
  },
  {
    label: "Description",
    value: "description",
    field_key: "description"
  },
  {
    label: "Labour Hours",
    value: "labourHours",
    field_key: "labourHours"
  },
  {
    label: "Created By",
    value: "createdById",
    field_key: "createdBy"
  },
  {
    label: "Parent",
    value: "parentId",
    field_key: "parentId"
  },
  {
    label: "Comment",
    value: "comment",
    field_key: "comment"
  },
  {
    label: "Is Archived",
    value: "isArchived",
    field_key: "isArchived"
  },
  {
    label: "Plant",
    value: "plant.name",
    field_key: "plants"
  },
  {
    label: "SLA",
    value: "sla.name",
    field_key: "sla"
  },
  {
    label: "Assigned Engineers",
    value: "assignedTo",
    field_key: "assignedToIds"
  },
  {
    label: "Field Engineers",
    value: "fieldEngineers",
    field_key: "fieldEngineerIds"
  },
  {
    label: "Attachments",
    value: "attachments",
    field_key: "attachments"
  },
];


const ExportForm = (props) => {
  const { plantId } = props;
  const [value, setValue] = useState(
    TASK_FIELDS.map((plant) => {
      return plant.value;
    })
  );

  const [exportLoading, setExportLoading] = useState(false);

  const handleFormSubmit = async () => {
    if (plantId) {
      props.filterParams.selectedPlant = [plantId];
    }

    try {
      setExportLoading(true);
      const response = await exportTask({
        ...props.filterParams,
        category: "corrective",
        headerData: value,
      });
      download(response, `correctivetask.csv`);
      successToast({ description: "Successfully Exported" });
      setExportLoading(false);
    } catch (err) {
      errorToast({ description: "Error happened while exporting tasks" });
      setExportLoading(false);
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button
        id="export--correctiveForm"
        color="primary"
        size="md"
        className="mt-1"
        isLoading={exportLoading}
        onClick={handleFormSubmit}
      >
        Export Task Fields
      </Button>
    );
  };

  const renderSaveButton = () => {
    return renderCreateButton();
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row lg="12" md="12">
              <Row className="match-height p-0">
                {TASK_FIELDS &&
                  TASK_FIELDS.map((task, key) => {
                    return (
                      <Col lg="6" md="6" sm="12" key={`${task} + ${key}`} className="pr-0 ">
                        <FormGroup>
                          <CardBody className="m-0 p-0 pr-0 ">
                            <CardText className="m-0">
                              <input
                                key={`${task}-${key}`}
                                type="checkbox"
                                checked={value.includes(task.value)}
                                id={`${task}-${key}`}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setValue([task.value, ...value]);
                                  } else {
                                    const arrayIndex = value.findIndex((e) => e === task.value);
                                    if (arrayIndex > -1) {
                                      value.splice(arrayIndex, 1);
                                      setValue([...value]);
                                    }
                                  }
                                }}
                              />
                              <Label className="ml-1" for={`${task}-${key}`} check>
                                {task.label.replace(/^./, function (str) {
                                  return str.toUpperCase();
                                })}
                              </Label>
                            </CardText>
                          </CardBody>
                        </FormGroup>
                      </Col>
                    );
                  })}
              </Row>

              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default ExportForm;

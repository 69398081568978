/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Label, Row } from "reactstrap";
import * as yup from "yup";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  RSelect,
} from "../../../../../components/Component";
import { EquipmentAndSpareContext } from "../../../../equipment_spare/EquipmentAndSpareProvider";
import { CAUSE, RECORD_TYPE, TRANSFER } from "../../../../equipment_spare/constants";

const TransactionForm = (props) => {
  const {
    isAddTransactionLoading,
    createStockTransaction,
    loadEquipmentOptions,
    equipmentOptions,
    currentEquipment,
    loadEquipment,
  } = useContext(EquipmentAndSpareContext);

  const WARE_HOUSE_OPTIONS = [
    {
      label: `Installed at site (${currentEquipment?.installedQuantity})`,
      value: `INSTALLED_AT_SITE`,
      title: "Installed at site",
    },
    {
      label: `Spares at site (${currentEquipment?.spareQuantity})`,
      value: `SPARES_AT_SITE`,
      title: "Spares at site",
    },
    {
      label: `External warehouse (${currentEquipment?.externalWarehouseQuantity})`,
      value: `EXTERNAL_WAREHOUSE`,
      title: "External warehouse",
    },
    {
      label: `BGE Warehouse (${currentEquipment?.bgeWarehouseQuantity})`,
      value: `BGE_WAREHOUSE`,
      title: "BGE Warehouse",
    },
    {
      label: `Supplier premises (${currentEquipment?.supplierPremisesQuantity})`,
      value: `SUPPLIER_PREMISES`,
      title: "Supplier premises",
    },
  ];

  let getSelectedQuantity;
  const [initialQuantity, setinitialnum] = useState(getSelectedQuantity);
  const transactionCreateSchema = yup.object({
    assetId: yup.string().required(),
    recordType: yup.string().required(),
    recordDate: yup.date().required().default(new Date()),
    quantity: yup
      .number()
      .required("This field is required")
      .typeError("Specify a Number")
      .min(1, "Min value 1.")
      .max(initialQuantity, `Entered quantity must be less than or equal to ${initialQuantity}.`)
      .integer("Invalid Number"),
    cause: yup.string(),
    description: yup.string(),
    fromWarehouse: yup.string().when("recordType", {
      is: (data) => data === "TRANSFER",
      then: yup.string().required(),
    }),
    warehouse: yup.string().when("recordType", {
      is: (data) => data === "ADD" || data === "SUBTRACT",
      then: yup.string().required(),
    }),
    toWarehouse: yup.string().when("recordType", {
      is: (data) => data === "TRANSFER",
      then: yup.string().required(),
    }),
  });
  const equipmentFormRef = useForm({
    resolver: yupResolver(transactionCreateSchema),
    mode: "onChange",
  });
  const {
    setValue,
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = equipmentFormRef;
  const AllQuantity = (data) => {
    switch (data) {
      case "INSTALLED_AT_SITE":
        return currentEquipment?.installedQuantity;
      case "SPARES_AT_SITE":
        return currentEquipment?.spareQuantity;
      case "EXTERNAL_WAREHOUSE":
        return currentEquipment?.externalWarehouseQuantity;
      case "BGE_WAREHOUSE":
        return currentEquipment?.bgeWarehouseQuantity;
      case "SUPPLIER_PREMISES":
        return currentEquipment?.supplierPremisesQuantity;
      default:
        return NaN;
    }
  };
  getSelectedQuantity = AllQuantity(getValues("fromWarehouse"));

  React.useEffect(() => {
    loadEquipmentOptions({ plantId: props.plantId });
  }, []);

  const [selectedCause, setSelectedCause] = useState({});

  const [selectedRecordType, setSelectedRecordType] = useState({});

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [stockId, setStockId] = useState(null);
  const [categoryId, setCategoryId] = useState("");
  const [supplier, setSupplier] = useState();
  const [equipmentId, setEquipmentId] = useState();

  const fromWarehouseValue = getValues("fromWarehouse");
  const recordTypeValue = getValues("recordType");

  useEffect(() => {
    if (recordTypeValue === TRANSFER) {
      setinitialnum(getSelectedQuantity);
    } else if (recordTypeValue === "SUBTRACT") {
      setinitialnum(currentEquipment.quantity);
    } else if (recordTypeValue === "ADD") {
      setinitialnum(1000000);
    }
  }, [fromWarehouseValue, recordTypeValue]);
  let assetOptions = [];
  let assetCategoryOptions = [];
  let manufacturerOptions = [];

  let category = {};
  let manufacturer = {};

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  useEffect(() => {
    setValue("quantity", 0);
  }, []);

  useEffect(() => {
    if (equipmentId) {
      loadEquipment(equipmentId);
    }
  }, [equipmentId]);

  if (equipmentOptions.length) {
    equipmentOptions.map((asset) => {
      assetOptions.push({
        equipmentId: asset.id,
        value: asset.assetId,
        label: asset.assets.model,
        assetCategoryId: asset.assets.assetCategoryId,
        manufacturerId: asset.assets.manufacturerId,
        stockId: asset.id,
      });
      return assetOptions;
    });
    equipmentOptions.map((assetCategory) => {
      assetCategoryOptions.push({
        value: assetCategory.assets.assetCategoryId,
        label: assetCategory.assets.assetCategories.name,
      });
      return assetCategoryOptions;
    });
    equipmentOptions.map((manufacturer) => {
      manufacturerOptions.push({
        value: manufacturer.assets.manufacturerId,
        label: manufacturer.assets.manufacturers.name,
      });
      return manufacturerOptions;
    });
  }

  for (let i = 0; i < manufacturerOptions?.length; i++) {
    manufacturer[manufacturerOptions[i].value] = manufacturerOptions[i].label;
  }

  for (let i = 0; i < assetCategoryOptions?.length; i++) {
    category[assetCategoryOptions[i].value] = assetCategoryOptions[i].label;
  }

  const handleFormSubmit = async (formData) => {
    if (formData.moveToPlantId) {
      formData.moveToPlantId = Number(formData.moveToPlantId);
    } else {
      delete formData.moveToPlantId;
    }
    const createBody = { ...formData, stockId: stockId, taskId: props.taskId };
    await createStockTransaction(createBody);
    if (props.onSuccessfulModal) props.onSuccessfulModal();
  };
  const renderSaveButton = () => {
    return (
      <Button color="primary" size="lg" disabled={isAddTransactionLoading} onClick={handleSubmit(handleFormSubmit)}>
        Add
      </Button>
    );
  };

  return (
    <>
      <Block size="lg">
        <PreviewCard>
          <BlockHead size="lg">
            <div className="justify-content-mb-between d-md-flex">
              <BlockHeadContent>
                <BlockTitle tag="h4">Add Transaction</BlockTitle>
              </BlockHeadContent>
            </div>
          </BlockHead>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col lg="4" className="mt-2">
                <FormGroup>
                  <Label className="form-label" htmlFor="assetId">
                    <span style={{ color: "indianred" }}>&#42;</span> Asset
                  </Label>
                  <Controller
                    control={control}
                    name="assetId"
                    rules={{ required: true }}
                    render={({ field, ref }) => {
                      const options = assetOptions || [];
                      const selectedValue = options?.find((e) => e.value === field.value);
                      return (
                        <RSelect
                          id="bge-asset"
                          {...field}
                          ref={ref}
                          value={selectedValue}
                          options={options}
                          onChange={(o) => {
                            setEquipmentId(o.equipmentId);
                            setSupplier(o.manufacturerId);
                            setStockId(o.stockId);
                            setCategoryId(o.assetCategoryId);
                            setValue("assetId", o.value, { shouldValidate: true });
                          }}
                        />
                      );
                    }}
                  />
                  {errors?.assetId && (
                    <span className="invalid" style={{ color: "red" }}>
                      Asset is required
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="4" className="mt-2">
                <FormGroup>
                  <Label className="form-label" htmlFor="category">
                    Category
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      disabled={true}
                      type="text"
                      name="category"
                      className="form-control"
                      value={category[categoryId]}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col lg="4" className="mt-2">
                <FormGroup>
                  <Label className="form-label" htmlFor="manufacturer">
                    Manufacturer
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      disabled={true}
                      type="text"
                      name="manufacturer"
                      className="form-control"
                      value={manufacturer[supplier]}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col md="12" lg="12" className="mt-2">
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    <span style={{ color: "indianred" }}>&#42;</span> Record Date
                  </Label>
                  <ReactDatePicker
                    maxDate={moment().toDate()}
                    className="form-control"
                    selected={selectedDate}
                    onChange={(date) => {
                      setValue("recordDate", date, { shouldValidate: true });
                      setSelectedDate(date);
                    }}
                    showTimeSelect
                    timeIntervals={30}
                    timeFormat="HH:mm"
                    timeCaption="time"
                    dateFormat="dd/MM/yyyy h:mm aa"
                  />
                  {errors?.recordDate && (
                    <span className="invalid" style={{ color: "red" }}>
                      Record Date is required
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="12" className="mt-2">
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    <span style={{ color: "indianred" }}>&#42;</span> Record Type
                  </Label>
                  <Controller
                    control={control}
                    name="recordType"
                    rules={{ required: true }}
                    render={({ field, ref }) => {
                      return (
                        <RSelect
                          defaultValue={selectedRecordType[1]}
                          {...field}
                          ref={ref}
                          value={!selectedRecordType ? RECORD_TYPE[0] : selectedRecordType}
                          options={RECORD_TYPE}
                          onChange={(o) => {
                            setValue("recordType", o.value, { shouldValidate: true });
                            setSelectedRecordType(o);
                          }}
                        />
                      );
                    }}
                  />
                  {errors?.recordType && (
                    <span className="invalid" style={{ color: "red" }}>
                      Record Type is required
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg="12" className="mt-2">
                <FormGroup>
                  <Label className="form-label" htmlFor="fv-full-name">
                    Cause
                  </Label>
                  <Controller
                    control={control}
                    name="cause"
                    rules={{ required: true }}
                    render={({ field, ref }) => {
                      return (
                        <RSelect
                          {...field}
                          ref={ref}
                          value={selectedCause}
                          options={CAUSE}
                          onChange={(o) => {
                            setValue("cause", o.value, { shouldValidate: true });
                            setSelectedCause(o);
                          }}
                        />
                      );
                    }}
                  />
                  {errors?.cause && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors?.cause.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col lg={12}>
                {selectedRecordType?.value == "ADD" || selectedRecordType?.value == "SUBTRACT" ? (
                  <FormGroup className="form-group">
                    <label className="form-label">
                      <span style={{ color: "indianred" }}>&#42;</span> Warehouse
                    </label>
                    <Controller
                      control={control}
                      name="warehouse"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = WARE_HOUSE_OPTIONS;
                        const selectedValue = options.filter((e) => field.value && field.value.includes(e.value));
                        return (
                          <RSelect
                            id="from-warehouse-select"
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            options={options}
                            onChange={(o) => setValue("warehouse", o.value, { shouldValidate: true })}
                          />
                        );
                      }}
                    />
                    {errors?.warehouse && (
                      <span className="invalid" style={{ color: "red" }}>
                        {errors?.warehouse.message}
                      </span>
                    )}
                  </FormGroup>
                ) : null}
              </Col>
              {selectedRecordType?.value === "TRANSFER" ? (
                <>
                  <Col lg="12" className="mt-2">
                    <FormGroup className="form-group">
                      <label className="form-label">From Warehouse</label>
                      <Controller
                        control={control}
                        name="fromWarehouse"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          const options = WARE_HOUSE_OPTIONS;
                          const selectedValue = options.filter((e) => field.value && field.value.includes(e.value));
                          return (
                            <RSelect
                              id="from-warehouse-select"
                              {...field}
                              ref={ref}
                              value={selectedValue}
                              options={options}
                              onChange={(o) => setValue("fromWarehouse", o.value, { shouldValidate: true })}
                            />
                          );
                        }}
                      />
                      {errors?.fromWarehouse && (
                        <span className="invalid" style={{ color: "red" }}>
                          {errors?.fromWarehouse.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="12" className="mt-2">
                    <FormGroup className="form-group">
                      <label className="form-label">To Warehouse</label>
                      <Controller
                        control={control}
                        name="toWarehouse"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          const options = WARE_HOUSE_OPTIONS;
                          const selectedValue = options.filter((e) => field.value && field.value.includes(e.value));
                          return (
                            <RSelect
                              id="to-warehouse-select"
                              {...field}
                              ref={ref}
                              value={selectedValue}
                              options={options}
                              onChange={(o) => setValue("toWarehouse", o.value, { shouldValidate: true })}
                            />
                          );
                        }}
                      />
                      {errors?.toWarehouse && (
                        <span className="invalid" style={{ color: "red" }}>
                          {errors?.toWarehouse.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                </>
              ) : null}
              <Col lg="12" className="mt-2">
                <FormGroup className="form-group">
                  <label className="form-label">
                    {" "}
                    <span style={{ color: "indianred" }}>&#42;</span> Quantity
                  </label>
                  <div className="form-control-wrap">
                    <input type="text" className="form-control" {...register("quantity")} />
                  </div>
                  {errors?.quantity && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors?.quantity.message}
                    </span>
                  )}
                </FormGroup>
              </Col>

              <Col lg="12" className="mt-2">
                <FormGroup className="form-group">
                  <label className="form-label">Description</label>
                  <div className="form-control-wrap">
                    <textarea className="form-control" {...register("description")} />
                  </div>
                  {errors?.description && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors?.description.message}
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-3">{renderSaveButton()}</div>
          </Form>
        </PreviewCard>
      </Block>
    </>
  );
};
export default TransactionForm;

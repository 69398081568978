/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Row, Col, FormGroup, Form, Label } from "reactstrap";
import { Block, PreviewCard, Button, RSelect } from "../../../../components/Component";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useBoolean from "../../../../hooks/useBoolean";
import { MaintenanceContext } from "../../../../providers/Maintenance.provider";
import { RemedialContext } from "../RemedialProvider";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { errorToast, successToast } from "../../../../components/toastify/Toastify";
import Attachments from "../../../../components/attachments/Attachment";
import { TASK } from "../../../../constants/PreferenceKeys";
import { PVPLANT_MANAGEMENT_VIEW_PAGE } from "../../../../constants/routes";
import { useHistory } from "react-router-dom";
import CreateFormAttachments from "../../../../components/attachments/CreateFormAttachment";
import { sortOption } from "../../../../utils/Utils";
import Head from "../../../../layout/head/Head";
import Skeleton from "react-loading-skeleton";
import { format } from "date-fns";
import { TextEditor } from "../../../../components/ReactQuillEditor";

const createSchema = yup.object({
  title: yup.string().required().default(""),
  priority: yup.string().notRequired().default(""),
  plantId: yup.number().required().default(""),
  status: yup.string().trim().required().default(""),
  startedAt: yup.date().required().default(""),
  resolvedAt: yup.date().min(yup.ref("startedAt")).nullable(),
  assignedToIds: yup.array().of(yup.number()).min(1).required(),
  fieldEngineerIds: yup.array().of(yup.number()).min(1).required(),
  description: yup.string().notRequired().nullable(),
  assetCategoryId: yup.number().notRequired().default(),
  parentId: yup.number().notRequired().nullable(),
  comment: yup.string().notRequired().nullable(),
});
const updateSchema = yup.object({
  title: yup.string().required().default(""),
  priority: yup.string().notRequired(),
  plantId: yup.number().required().default(""),
  status: yup.string().trim().required().default(""),
  startedAt: yup.date().required().default(""),
  resolvedAt: yup.date().min(yup.ref("startedAt")).nullable(),
  assignedToIds: yup.array().of(yup.number()).min(1).required(),
  fieldEngineerIds: yup.array().of(yup.number()).min(1).required(),
  description: yup.string().notRequired().nullable(),
  assetCategoryId: yup.number().notRequired().default(),
  parentId: yup.number().notRequired().nullable(),
  comment: yup.string().notRequired().nullable(),
});

const RemedialForm = (props) => {
  const history = useHistory();
  const { plantId: currentPlantId } = props

  const { filterOptions, createRemedial, createLoading } = useContext(MaintenanceContext);

  const [isEditMode, updateEditMode] = useBoolean(false);
  const [plantId, setPlantId] = useState();

  const [attachments, setAttachments] = useState([]);

  const remedialContext = useContext(RemedialContext);
  const { loadingTasks, loadRemedialTasks, tasksColumnSettings } = remedialContext;

  const userFormRef = useForm({
    resolver: yupResolver(props.taskId ? updateSchema : createSchema),
  });
  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = userFormRef;

  useEffect(() => {
    if (props.plantId) {
      onChangePlant(parseInt(props.plantId));
    }
    if (!props.currentViewTask) {
      reset({ startedAt: new Date(), status: "OPEN", plantId: props.plantId });
    }
    if (props.currentViewTask) {
      setPlantId(props.currentViewTask.plantId);
      reset({ ...props.currentViewTask });
      setAttachments(
        props.currentViewTask.attachment.map((item) => {
          return { ...item.files, status: "EXISTING" };
        })
      );
      updateEditMode.on();
    }
  }, [props.currentViewTask]);

  const handleFormSubmit = (formData) => {

    // Only date is required so removed timestamp which double converts to UTC;
    if (formData.resolvedAt) {
      formData.resolvedAt = format(formData.resolvedAt, 'yyyy-MM-dd')
    }
    if (formData.startedAt) {
      formData.startedAt = format(formData.startedAt, 'yyyy-MM-dd')
    }
    // ***
    
    if (props.taskId) {
      props
        .updateTask(props.currentViewTask.id, { ...formData })
        .then((res) => {
          loadRemedialTasks({plantId: currentPlantId});
          if (props.onSuccessfulModal) props.onSuccessfulModal();
          successToast({ description: "Successfully Updated" });
        })
        .catch((e) => {
          errorToast({ description: "Error happened while updating Remedial maintenance" });
        });
    } else {
      createRemedial({ ...formData, attachments })
        .then((res) => {
          loadRemedialTasks({plantId: currentPlantId})
          if (props.onSuccessfulModal) props.onSuccessfulModal();
          successToast({ description: "Successfully Created" });
          
        })
        .catch((e) => {
          errorToast({ description: "Error happened while creating Remedial maintenance" });
        });
    }
  };
  let engineers = [];
  filterOptions.plants?.map((item) => {
    if (item.value === plantId) {
      for (let i = 0; i < filterOptions.assignedEngineer.length; i++) {
        if (item.fieldEngineerIds?.includes(filterOptions.assignedEngineer[i].value)) {
          engineers.push(filterOptions.assignedEngineer[i]);
        }
      }
    }
  });
  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button
        id="add-remedial"
        color="primary"
        size="lg"
        isLoading={createLoading}
        onClick={createLoading ? null : handleSubmit(handleFormSubmit)}
      >
        Add
      </Button>
    );
  };

  const renderEditButton = () => {
    return (
      <>
        <Button
          id="save-remedial"
          color="primary"
          isLoading={loadingTasks}
          className={"mr-1"}
          size="lg"
          onClick={loadingTasks ? null : handleSubmit(handleFormSubmit)}
        >
          Save Information
        </Button>
        <Button id="center-remedial" size="lg" color="outline-primary" onClick={() => props.onSuccessfulModal()}>
          Cancel
        </Button>
      </>
    );
  };

  const renderSaveButton = () => {
    if (isEditMode) {
      return renderEditButton();
    } else {
      return renderCreateButton();
    }
  };

  const onChangePlant = (value) => {
    setPlantId(value);
    setValue("plantId", value, { shouldValidate: true });
    setValue("fieldEngineerIds", []);
    setValue("assignedToIds", []);
  };

  const onChangeDate = (date) => {
    setValue("startedAt", date);
  };

  const attachmentFiles = (files) => {
    if (files) {
      setAttachments(files);
    }
  };

  return (
    <div className="overflow-auto h-max-550px">
      <Head title={props.taskId ? "Brighter App | Remedial | Edit" : "Brighter App | Remedial | Create"} />
      <Block size="lg">
        <PreviewCard>
          {!props?.taskId || props?.currentViewTask ? (
            <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
              <Row className="g-4">
                <Col lg="6">
                  <FormGroup>
                    <Label className="form-label" htmlFor="fv-full-name">
                      <span style={{ color: "indianred" }}>&#42;</span>Task Name
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        id="title-input-remedial"
                        {...register("title")}
                        type="text"
                        name="title"
                        className="form-control"
                      />
                    </div>
                    {errors.title && (
                      <span className="invalid" style={{ color: "red" }}>
                        Title is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <Label className="form-label" htmlFor="fv-full-name">
                      Priority
                    </Label>
                    <Controller
                      control={control}
                      name="priority"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = filterOptions.priorityStatuses;
                        const selectedValue = options.find((e) => e.value === field.value);

                        return (
                          <RSelect
                            id="priority-remedial-select"
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            options={options}
                            onChange={(o) => setValue("priority", o.value, { shouldValidate: true })}
                          />
                        );
                      }}
                    />
                    {errors?.priority && (
                      <span className="invalid" style={{ color: "red" }}>
                        Priority is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="form-group">
                    <label className="form-label">
                      <span style={{ color: "indianred" }}>&#42;</span>Status
                    </label>
                    <Controller
                      control={control}
                      name="status"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = filterOptions.taskStatuses;
                        const selectedValue = options.find((e) => e.value === field.value);

                        return (
                          <RSelect
                            id="status-remedial-select"
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            options={options}
                            defaultValue={options[0]}
                            onChange={(o) => setValue("status", o.value, { shouldValidate: true })}
                          />
                        );
                      }}
                    />
                    {errors?.status && (
                      <span className="invalid" style={{ color: "red" }}>
                        Status is required
                      </span>
                    )}
                  </FormGroup>
                </Col>
                {props.plantId && (
                  <Col lg="6">
                    <FormGroup>
                      <Label className="form-label" htmlFor="fv-full-name">
                        <span style={{ color: "indianred" }}>&#42;</span>Plant Name
                      </Label>
                      <Controller
                        control={control}
                        name="plantId"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          const options = filterOptions.plants;
                          const selectedValue = options.find((e) => e.value === parseInt(props.plantId));
                          return <RSelect {...field} ref={ref} value={selectedValue} isDisabled />;
                        }}
                      />
                      {errors?.plantId && (
                        <span className="invalid" style={{ color: "red" }}>
                          Plant name is required
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                )}
                {!props.plantId && (
                  <Col lg="6">
                    <FormGroup>
                      <Label className="form-label" htmlFor="fv-full-name">
                        <span style={{ color: "indianred" }}>&#42;</span>Plant Name
                      </Label>
                      <Controller
                        control={control}
                        name="plantId"
                        rules={{ required: true }}
                        render={({ field, ref }) => {
                          const options = sortOption(filterOptions.plants);
                          const selectedValue = options.find((e) => e.value === field.value);
                          return (
                            <RSelect
                              id="plant-remedial-select"
                              {...field}
                              ref={ref}
                              value={selectedValue}
                              options={sortOption(options)}
                              onChange={(o) => {
                                onChangePlant(o.value);
                              }}
                            />
                          );
                        }}
                      />
                      {errors?.plantId && (
                        <span className="invalid" style={{ color: "red" }}>
                          Plant name is required
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                )}
                <Col lg="6">
                  <FormGroup className="form-group">
                    <label className="form-label">
                      <span style={{ color: "indianred" }}>&#42;</span>Field Engineer
                    </label>
                    <Controller
                      control={control}
                      name="fieldEngineerIds"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = sortOption(engineers);
                        const selectedValue = options.filter((e) => field.value && field.value.includes(e.value));

                        return (
                          <RSelect
                            id="engineer-remedial-select"
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            isMulti
                            options={sortOption(options)}
                            onChange={(o) =>
                              setValue(
                                "fieldEngineerIds",
                                o.map((item) => item.value),
                                { shouldValidate: true }
                              )
                            }
                          />
                        );
                      }}
                    />
                    {errors?.fieldEngineerIds && (
                      <span className="invalid" style={{ color: "red" }}>
                        Field Engineer is required
                      </span>
                    )}
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup>
                    <Label className="form-label" htmlFor="fv-full-name">
                      Start Date
                    </Label>
                    <div className="form-control-wrap">
                      <Controller
                        control={control}
                        name="startedAt"
                        render={({ field }) => {
                          return (
                            <DatePicker
                              id="startdate-remedial-select"
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              dateFormat="dd/MM/yyyy HH:mm"
                              selected={field.value ? field.value : new Date()}
                              onChange={(date) => onChangeDate(date)}
                              className="form-control date-picker"
                            />
                          );
                        }}
                      />
                    </div>
                    {errors?.startedAt && (
                      <span className="invalid" style={{ color: "red" }}>
                        Start Date is required
                      </span>
                    )}
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup className="form-group">
                    <label className="form-label">
                      <span style={{ color: "indianred" }}>&#42;</span>Assigned To
                    </label>
                    <Controller
                      control={control}
                      name="assignedToIds"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = sortOption(engineers);
                        const selectedValue = options.filter((e) => field.value && field.value.includes(e.value));

                        return (
                          <RSelect
                            id="assignedto-remedial-select"
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            isMulti
                            options={sortOption(options)}
                            onChange={(o) => {
                              setValue(
                                "assignedToIds",
                                o.map((item) => item.value),
                                { shouldValidate: true }
                              );
                            }}
                          />
                        );
                      }}
                    />
                    {errors?.assignedToIds && (
                      <span className="invalid" style={{ color: "red" }}>
                        Assigned To is required
                      </span>
                    )}
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup className="form-group">
                    <label className="form-label">Resolved Date</label>
                    <div className="form-control-wrap">
                      <Controller
                        control={control}
                        name="resolvedAt"
                        render={({ field, ref }) => {
                          return (
                            <DatePicker
                              id="resolved--remedial-select"
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              dateFormat="dd/MM/yyyy HH:mm"
                              selected={field.value}
                              onChange={(date) => setValue("resolvedAt", date)}
                              className="form-control date-picker"
                            />
                          );
                        }}
                      />
                    </div>
                    {errors?.resolvedAt && (
                      <span className="invalid" style={{ color: "red" }}>
                        {errors?.resolvedAt.message.includes("later") &&
                          `Resolved Date should be greater than Start Date`}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="form-group">
                    <label className="form-label">Asset Category</label>
                    <Controller
                      control={control}
                      name="assetCategoryId"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = sortOption(filterOptions.assetCategories);
                        const selectedValue = options.find((e) => e.value === field.value);
                        return (
                          <RSelect
                            id="assertcategory-remedial-select"
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            options={sortOption(options)}
                            placeholder="Select the Asset category "
                            onChange={(o) => setValue("assetCategoryId", o.value)}
                          />
                        );
                      }}
                    />
                    {errors?.assetCategoryId && (
                      <span className="invalid" style={{ color: "red" }}>
                        Asset Category is required
                      </span>
                    )}
                  </FormGroup>
                </Col>

                <Col lg="12" md="12">
                  <FormGroup className="form-group">
                    <label className="form-label">Comment</label>
                    <div className="form-control-wrap">
                    <Controller
                      control={control}
                      name="comment"
                      render={({ field, ref }) => {
                        return (
                          <TextEditor
                            field={field}
                            setValue={setValue}
                            fieldName={"comment"}
                          />

                        );
                      }}
                    />
                  </div>
                  {errors?.comment && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors?.comment.message}
                    </span>
                  )}
                  </FormGroup>
                </Col>
                <Col lg="12" md="12">
                  <FormGroup className="form-group">
                    <label className="form-label">Description</label>
                    <div className="form-control-wrap">
                    <Controller
                      control={control}
                      name="description"
                      render={({ field, ref }) => {
                        return (
                          <TextEditor
                            field={field}
                            setValue={setValue}
                            fieldName={"description"}
                          />

                        );
                      }}
                    />
                  </div>
                  {errors?.description && (
                    <span className="invalid" style={{ color: "red" }}>
                      {errors?.description.message}
                    </span>
                  )}
                  </FormGroup>
                </Col>
            
                {/* <Col lg="12" md="12">
                  <FormGroup>
                    <Label className="form-label" htmlFor="fv-full-name">
                      Parent Task
                    </Label>
                    <Controller
                      control={control}
                      name="parentId"
                      rules={{ required: true }}
                      render={({ field, ref }) => {
                        const options = sortOption(remedialTasks);

                        const selectedValue = options.find((e) => e.value === field.value);

                        return (
                          <RSelect
                            id="parendid-remedial-select"
                            {...field}
                            ref={ref}
                            value={selectedValue}
                            options={sortOption(options)}
                            onChange={(o) => {
                              onChangeParentTask(o.value);
                            }}
                          />
                        );
                      }}
                    />
                    {errors?.parentId && <span className="invalid"></span>}
                  </FormGroup>
                </Col> */}

                {isEditMode && props.currentViewTask && (
                  <Col lg="12">
                    <FormGroup className="form-group">
                      <div className="form-control-wrap">
                        <Attachments
                          module={TASK}
                          id={props.currentViewTask.id}
                          attachments={props.currentViewTask.attachment}
                          subModule={""}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                )}
                {!isEditMode && (
                  <Col lg="12">
                    <FormGroup className="form-group">
                      <div className="form-control-wrap">
                        <CreateFormAttachments attachmentFiles={attachmentFiles} />
                      </div>
                    </FormGroup>
                  </Col>
                )}
                <Col xl="12" className="d-flex justify-content-end">
                  {renderSaveButton()}
                </Col>
              </Row>
            </Form>
          ) : (
            <Skeleton count={15} width={"100"} />
          )}
        </PreviewCard>
      </Block>
    </div>
  );
};

export default RemedialForm;

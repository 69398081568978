import React from "react";
import useBoolean from "../hooks/useBoolean";
import {
    getActivityLogList
} from "../repositories/ActivityLogRepository";

const initialPaginationState = {
    page: 1,
    size: 10,
}

export const ActivityLogContext = React.createContext()

export const ActivityLogProvider = props => {

    const [activityLogsList, setActivityLogsList] = React.useState([])
    const [pagination, setPagination] = React.useState(Object.assign({}, initialPaginationState))

    //Loaders
    const [activtyLogLoading, activityLogState] = useBoolean(false)


    const loadActivityLogList = async (params) => {
        activityLogState.on()
        try {
            
            const response = await getActivityLogList(params)
            setActivityLogsList(response.activityLogList)
        } catch (e) {
            // CAPTURE EXCEPTION
            throw e
        } finally {
            activityLogState.off()
        }
    }

    
    const mContext = {
        activityLogsList,
        pagination,
        loadActivityLogList,
        activtyLogLoading,
        updateShowListSize: size => setPagination({ ...pagination, size, page: 1 }),
        updateShowListPage: page => setPagination({ ...pagination, page })
    }
    return <ActivityLogContext.Provider value={mContext}>
        {props.children}
    </ActivityLogContext.Provider>
}
export const withActivityLogProvider = (Container, containerProps) => props => <ActivityLogProvider>
    <Container {...containerProps} />
</ActivityLogProvider>
import React, { useContext, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { MaintenanceContext } from "../../../providers/Maintenance.provider";
import { useLocation } from "react-router-dom";
import useBoolean from "../../../hooks/useBoolean";
import PlantRemedialList from "../components/PvPlantRemedialMaintananceList"
import { RemedialContext } from "../../maintenance/remedial/RemedialProvider";
import { updateViewSettings } from "../../../repositories/ViewRepository";
import { MAINTENANCE_CATEGORY } from "../../../constants/constants";

const PvPlantRemedialMaintenanceContainer = (props) => {
    const { plantId } = props
    const location = useLocation().search;
    const [isMounted, setIsMounted] = useBoolean(false);

    const queryParams = new URLSearchParams(location);
    const dashboard = queryParams.get("dashboard");
    const tab = queryParams.get("tab");
    const status = queryParams.get("status");

    const { handleApplyFilter, filterParams, getTableSettings, loadRemedialTasks, setRemedialList, tasksColumnSettings, initialFilterParams } = useContext(RemedialContext);
    const { loadFilterOptions, loadAllUsers } = useContext(MaintenanceContext);

    const fetchRemedialTasksData = async () => {
        await loadRemedialTasks({ plantId })
    };

    useEffect(() => {
        if (isMounted) {
            fetchRemedialTasksData()
        }
    }, [filterParams, isMounted, tasksColumnSettings])


    useEffect(() => {
        if (tab === MAINTENANCE_CATEGORY.REMEDIAL) {
            if (status) {
                handleApplyFilter({ ...initialFilterParams, selectedTaskStatus: [status], dashboard: dashboard });
            }
            getTableSettings({ entityType: "REMEDIAL_PLANT", }, !!!status).then(() => {
                setIsMounted.on();
            });
            loadFilterOptions();
            loadAllUsers();
        }
    }, [tab]);

    useEffect(() => {
        if (isMounted) {
            updateViewSettings(tasksColumnSettings)
        }
    }, [tasksColumnSettings])

    return (
        <React.Fragment>
            <Head title="Brighter App | Remedial Maintenance" />
            <Content>
                <PlantRemedialList 
                    plantStatus={props.plantStatus}
                    isArchived={props.isArchived}
                    plantId={props.plantId} />
            </Content>
        </React.Fragment>
    );
};

export default PvPlantRemedialMaintenanceContainer


import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { updateViewSettings } from "../../../repositories/ViewRepository";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import useBoolean from "../../../hooks/useBoolean";
import { CorrectiveContext, withCorrectiveProvider } from "../../maintenance/corrective/CorrectiveProvider";
import PlantCorrectiveList from "../components/PvPlantCorrectiveMaintenanceList";
import { MaintenanceContext } from "../../../providers/Maintenance.provider";
import { MAINTENANCE_CATEGORY } from "../../../constants/constants";

const PvPlantCorrectiveContainer = (props) => {
    const location = useLocation().search;
    const tab = new URLSearchParams(location).get("tab");
    const dashboard = new URLSearchParams(location).get("dashboard");
    const queryParams = new URLSearchParams(location);
    const { getTableSettings, handleApplyFilter, filterParams, loadOptions, loadCorrectiveTasks, tasksColumnSettings, setCorrectiveList, initialFilterParams } =
        useContext(CorrectiveContext);
    const { loadFilterOptions, loadAllUsers } = useContext(MaintenanceContext);

    const { plantId } = props;

    const [isMounted, setIsMounted] = useBoolean(false);
    const status = queryParams.get("status");

    const fetchCorrectiveTasksData = async () => {
        await loadCorrectiveTasks({ plantId })
    };

    useEffect(() => {
        if(isMounted){
            fetchCorrectiveTasksData()
        }
    }, [filterParams, isMounted, tasksColumnSettings])


    useEffect(() => {
        if (tab === MAINTENANCE_CATEGORY.CORRECTIVE) {
            if (status) {
                handleApplyFilter({ ...initialFilterParams, selectedTaskStatus: [status], dashboard: dashboard });
            }
            getTableSettings({ entityType: "CORRECTIVE_PLANT", }, !!!status).then(() => {
                setIsMounted.on();
            });
            loadFilterOptions();
            loadAllUsers();
            loadFilterOptions();
        }
    }, [tab]);

    useEffect(() => {
        if (isMounted) {
            updateViewSettings(tasksColumnSettings)
        }
    }, [tasksColumnSettings])

    return (
        <React.Fragment>
            <Head title="Brighter App | Corrective Task" />
            <Content>
                <PlantCorrectiveList
                    plantId={props.plantId}
                    isArchived={props.isArchived}
                    plantStatus={props.plantStatus}
                />
            </Content>
        </React.Fragment>
    );
};

export default withCorrectiveProvider(PvPlantCorrectiveContainer);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../../../layout/content/Content";
import Head from "../../../../../layout/head/Head";
import { withPreventativeTaskProvider, PreventativeTaskContext } from "../PreventativeTaskProvider";
import PreventativeTaskList from "../components/PreventativeTaskList";
import { useLocation, useHistory } from "react-router-dom";
import { MaintenanceContext } from "../../../../../providers/Maintenance.provider"
import useBoolean from "../../../../../hooks/useBoolean";
import { updateViewSettings } from "../../../../../repositories/ViewRepository";

const PreventiveMaintenanceContainer = (props) => {
  const location = useLocation().search;
  const dashboard = new URLSearchParams(location).get("dashboard");
  const plantId = new URLSearchParams(location).get("plantId");
  const queryParams = new URLSearchParams(location);
  const { getTableSettings, handleApplyFilter, filterParams, loadOptions, loadPreventativeTasks, tasksColumnSettings } = useContext(PreventativeTaskContext);
  const { loadAllUsers } = useContext(MaintenanceContext);


  const [isMounted, setIsMounted] = useBoolean(false);
  const status = queryParams.get("status");

  const fetchPreventativeTasksData = async () => {
   await loadPreventativeTasks()
  };

  useEffect(() => {
    if (isMounted) {
      fetchPreventativeTasksData()
    }
  }, [filterParams, isMounted, tasksColumnSettings])


  useEffect(() => {
    if (status) {
      handleApplyFilter({ ...filterParams, selectedTaskStatus: [status], dashboard });
    }
    getTableSettings({ entityType: "PREVENTATIVE_MAINTENANCE", }, !!!status).then(() => {
      setIsMounted.on();
    });

    loadOptions();
  }, []);

  useEffect(() => {
    if(isMounted) {
      updateViewSettings(tasksColumnSettings)
    }
  }, [tasksColumnSettings])

  return (
    <React.Fragment>
      <Head title="Brighter App | Preventative Task" />
      <Content>
        <PreventativeTaskList />
      </Content>
    </React.Fragment>
  );
};

export default withPreventativeTaskProvider(PreventiveMaintenanceContainer);
